import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Card, CardContent, Button, Avatar, ToggleButtonGroup, ToggleButton, CircularProgress, Menu, MenuItem, TextField, Dialog, DialogContent, Select, FormControl, FormHelperText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { IconEye, IconFile, IconFilePlus } from '@tabler/icons-react';
import avatar1 from '../../Images/user-1.jpg'
import avatar2 from '../../Images/user-2.jpg'
import avatar3 from '../../Images/user-3.jpg'
import avatar4 from '../../Images/user-4.jpg'
import HashLoader from "react-spinners/RingLoader";
import Breadcrumb from '../MainPages/Breadcrumb';
const GeneratedFiles = () => {
    const [projects, setprojects] = useState([]);
    console.log(projects, 'kwqdjkqwjdqjdqhu');

    const [projectNames, setprojectNames] = useState([]);
    const userInfo = JSON.parse(sessionStorage.getItem('user-info'));
    const [loading, setLoading] = useState(true);
    const groupAvatars = [avatar1, avatar2, avatar3, avatar4];
    const navigate = useNavigate();
    const reactcolors = ['#8ad9bc', '#6bc8f3', '#8cd6bb', '#fa9962', '#e875ba']
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [alignment, setAlignment] = useState('Assigned');
    const [successtestDialogOpen, setsuccesstestDialogOpen] = useState(false);
    const [SelectedId, setSelectedId] = useState('')
    const [inputTextid, setinputTextid] = useState([])
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    const id = params.get("id");
    const namefromurl = params.get("name");
    const [MasterinputName, setMasterinputName] = useState(namefromurl)
    const getProjectdataView = async () => {
        try {
            setLoading(false)
            const response = await axios.get(`apiV1/project/${id}/generate-master-input/`);
            console.log(response.data);
            if (response.status === 200) {
                setinputTextid(Object.keys(response.data.data))
                setLoading(false)
                const firstKey = Object.keys(response.data.data)[0];
                if (namefromurl === null) {
                    setSelectedId(firstKey);
                    setMasterinputName(firstKey);
                }
            }
        } catch (error) {
            console.error('Error fetching project data:', error);
            setLoading(true)
        }
    };
    useEffect(() => {
        getProjectdataView()
    }, [])
    const getProjects = async () => {
        try {
            setLoading(true)
            const response = await axios.get(`/apiV1/project/${id}/generate-master-input/?master_input_name=${MasterinputName}`)
            if (response.status == 200) {
                console.log(response);
                setprojects(response.data.data)
                setprojectNames(Object.keys(response.data.data))
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }
    useEffect(() => {
        if (SelectedId || namefromurl) {
            getProjects()
        }
    }, [SelectedId, namefromurl])
    const handleGenerate = async (data) => {
        try {
            setLoading(true)
            setsuccesstestDialogOpen(true)
            let Data = {
                master_input_name: MasterinputName,
                doc_type: data
            }
            const response = await axios.post(`/apiV1/generate-documents/${id}/`, Data);
            console.log(response.data, 'responseasads')
            if (response.status === 200) {
                getProjects()
                setLoading(false)
                setsuccesstestDialogOpen(false)

            }
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
            setLoading(false)
            setsuccesstestDialogOpen(false)
        }
    }
    const handleGenerateAll = async()=>
        {
            try{
                setsuccesstestDialogOpen(true)
                let req = {
                    master_input_name:SelectedId?SelectedId :MasterinputName
                    }
            const response = await axios.post(`/apiV1/${id}/generate-all-doc/`,req)
            if(response.status === 200)
                {
                    setsuccesstestDialogOpen(false)
                }
            }
            catch(err)
            {
                console.log(err);
                setsuccesstestDialogOpen(false)
            }
    }
    const GeneratedFiles = [
        { id: 1, name: "BRD", docName: "brd", type: "docx", description: "Business requirements must align with project goals, including clear objectives, functional needs, and stakeholder expectations.", status: projects.brd === false ? "false" : "true" },
        { id: 2, name: "FRD", docName: "frd", type: "docx", description: "Functional requirements describe system features, user interactions, and expected behavior to meet business and technical goals.", status: projects.frd === false ? "false" : "true" },
        { id: 3, name: "SRS", docName: "srs", type: "docx", description: "The SRS defines the software's functionality, performance, and behavior in detail. It ensures the system meets user needs and aligns with business objectives", status: projects.srs === false ? "false" : "true" },
        { id: 4, name: "TASK", docName: "task", type: "xlsx", description: "Detailed tasks break deliverables into actionable steps, while resource allocation logs track assigned resources for efficient project management", status: projects.task === false ? "false" : "true" },
        { id: 5, name: "TEST CASES", docName: "test_cases", type: "xlsx", description: "Test cases outline scenarios, inputs, expected outputs, and validation steps to ensure system functionality and performance.", status: projects.test_cases === false ? "false" : "true" },
        { id: 6, name: "USER STORY", docName: "user_story", type: "docx", description: "Comprehensive user stories outline software functionalities from the end-user's perspective to ensure clarity and alignment with user needs.", status: projects.user_story === false ? "false" : "true" },
        { id: 7, name: "WIRE FRAMES", docName: "wire_frames", type: "xlsx", description: "Wireframes represent layout, structure, and design elements of a user interface to guide development and usability.", status: projects.brd !== false && projects.frd !== false && projects.wire_frames !== false ? "true" : "false" }
    ];
    const handleIdChange = (event) => {
        const selectedId = event.target.value;
        setMasterinputName(selectedId)
        setSelectedId(selectedId);
    };
    const BCrumb = [
        {
            to: `/documents`,
            title: 'Back',
        },
    ];
    const allStatusTrue = GeneratedFiles.every(file => file.status === "true");
    return (
        <>
            <Breadcrumb breadcrumbs={BCrumb} />
            <Card sx={{ display: 'flex', flexDirection: 'column', margin: "16px 0px 0px 0px", justifyContent: "space-between" }}>
              
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600,ml:5,fontSize:20}}>
                        Documents
                    </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                        {!allStatusTrue ?<Button
                                variant="contained"
                                color="primary"
                                onClick={handleGenerateAll}
                                sx={{
                                    borderRadius: "10px",
                                    height: "2.8rem",
                                    textTransform: "none",
                                    // fontWeight: 600,
                            }}
                            >
                            Generate All
                        </Button>:""}
                        <Box>
                            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                Releases
                            </Typography>
                            <FormControl >
                                <Select
                                    value={MasterinputName}
                                    onChange={handleIdChange}
                                    displayEmpty
                                    inputProps={{ "aria-label": "Select ID" }}
                                    sx={{
                                        borderRadius: "10px",
                                        maxHeight: "2.8rem",
                                    }}
                                    disabled={loading}
                                >
                                    {Array.isArray(inputTextid) && inputTextid.length > 0 ? (
                                        inputTextid.map((name, index) => (
                                            <MenuItem key={index} value={name}>
                                                {name}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem disabled>No data available</MenuItem>
                                    )}
                                </Select>
                                <FormHelperText>Select a Release to view the Documents</FormHelperText>
                            </FormControl>
                        </Box>
                    </Box>
                    </Box>
                    <CardContent>
                    <Box sx={{ padding:"0px 20px 20px 20px" }}>
                        <Grid container spacing={4}>
                            {GeneratedFiles.map((label, index) => {
                                const brdStatus = GeneratedFiles.find((file) => file.name === "BRD")?.status === "true";
                                const frdStatus = GeneratedFiles.find((file) => file.name === "FRD")?.status === "true";
                                const srsStatus = GeneratedFiles.find((file) => file.name === "SRS")?.status === "true";
                                const isBRDUnlocked = brdStatus;
                                const isFRDUnlocked = isBRDUnlocked && frdStatus;
                                // const shouldDisable = label.name === "BRD" ? false : label.name === "FRD" ? !isBRDUnlocked : (label.name === "SRS" || label.name === "WIRE FRAMES") ? !(isBRDUnlocked && isFRDUnlocked) : !isBRDUnlocked || (isBRDUnlocked && !isFRDUnlocked && label.name !== "FRD");
                                 const shouldDisable = label.name === "BRD" ? false : label.name === "FRD"? !isBRDUnlocked : label.name === "SRS"? !isBRDUnlocked: label.name === "WIRE FRAMES"? !(isBRDUnlocked && isFRDUnlocked): !isBRDUnlocked || (isBRDUnlocked && !isFRDUnlocked && label.name !== "FRD");
                                return (
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                        <Card
                                            sx={{
                                                width: '100%',
                                                minHeight: '270px',
                                                display: 'flex',
                                                backgroundColor: "#f6f7fb",
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                transition: 'box-shadow 0.3s ease, transform 0.3s ease',
                                                boxShadow: hoveredIndex === index? "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px" : 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                                '&:hover': {
                                                    boxShadow: shouldDisable? 'none': "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                                    backgroundColor: shouldDisable ? "#f6f7fb" : "#fff",
                                                    transform: shouldDisable ? 'none' : 'translateY(-5px)',
                                                },
                                            }}
                                            onMouseEnter={() => !shouldDisable && setHoveredIndex(index)}
                                            onMouseLeave={() => !shouldDisable && setHoveredIndex(null)}
                                        >
                                            <CardContent sx={{ flexGrow: 1 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Box
                                                        sx={{
                                                            width: 60,
                                                            height: 60,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            borderRadius: '4px',
                                                            color: 'white',
                                                            fontSize: '30px',
                                                            fontWeight: 'bold',
                                                            backgroundColor: reactcolors[index % reactcolors.length],
                                                        }}
                                                    >
                                                        {label.name.charAt(0)}
                                                    </Box>

                                                    <Typography variant="h6" sx={{ marginLeft: 2 }}>
                                                        {label.name}
                                                    </Typography>
                                                </Box>
                                                <Typography sx={{ fontSize: '16px', lineHeight: "1.3rem", mt: 2 }}>{label.description}</Typography>
                                            </CardContent>
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{
                                                    borderTop: '2px dashed #c0c1c4',
                                                    padding: "10px 30px 10px 30px"
                                                }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}
                                                    fullWidth
                                                    onClick={() => shouldDisable ? null : (label.status === "true"
                                                        ? navigate(`/generateddocs?id=${id}&name=${MasterinputName}&docName=${label.docName}&docType=${label.type}`)
                                                        : handleGenerate(label.name))}
                                                    disabled={shouldDisable}
                                                >
                                                    {label.status === "true" ? `View ${label.name}` : `Generate ${label.name}`} {label.status === "true" ? <IconEye /> : <IconFile />}
                                                </Button>
                                            </Box>
                                        </Card>
                                    </Grid>
                                );
                            })}

                        </Grid>
                    </Box>
                </CardContent>
            </Card>
            <Dialog
                open={successtestDialogOpen}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        setsuccesstestDialogOpen(false);
                    }
                }}
                aria-labelledby="success-dialog-title"
                aria-describedby="success-dialog-description"
                maxWidth="sm"
                fullWidth
            >
                <DialogContent dividers className='text-center py-3'>
                    <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: '20px', marginBottom: '10px' }}>
                        <HashLoader
                            size={50}
                            color={"#5a1bc2"}
                            loading={successtestDialogOpen}
                        />
                    </Box>
                    <Typography variant="h6" className='blue_dark_text font_weight_700'>
                        Generation in progress. Please wait for a few seconds.....
                    </Typography>
                </DialogContent>
            </Dialog>


        </>
    );
};

export default GeneratedFiles;
