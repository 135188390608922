import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { IconClockHour4 } from "@tabler/icons-react";

const ComingSoon = () => {
    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{
                height: "80vh", 
                padding: "10px 30px 0px 30px",
                backgroundColor: "#fff",
                boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                margin: "0px 0px 0px 9px",
            }}
        >
            <Grid item xs={12} sm={8} md={6} textAlign="center">
                <IconClockHour4 size={64} color="#7a5af9" />
                <Typography
                    variant="h2"
                    style={{ fontWeight: 700, color: "#333", marginTop: "16px", marginBottom: "8px" }}
                >
                    Coming Soon!
                </Typography>
                <Typography
                    variant="subtitle1"
                    style={{ color: "#666", marginBottom: "24px" }}
                >
                    We're working hard to bring you something amazing. Stay tuned!
                </Typography>
                {/* <Button
                    variant="contained"
                    size="large"
                    style={{
                        backgroundColor: "#7a5af9",
                        color: "#fff",
                        textTransform: "none",
                    }}
                    onClick={() => {
                        console.log("Notify Me Clicked");
                    }}
                >
                    Notify Me
                </Button> */}
            </Grid>
        </Grid>
    );
};

export default ComingSoon;
