import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, LinearProgress, TextField, Typography } from '@mui/material'
import { Box, Grid, margin } from '@mui/system';
import { IconBookmark, IconCircleCheck, IconFile, IconSend2, IconTrash, IconWand } from '@tabler/icons-react';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Breadcrumb from '../MainPages/Breadcrumb';
import PageContainer from '../MainPages/PageContainer';

const Managerinput = () => {
  const navigate = useNavigate()
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [searchParams] = useSearchParams();
  const stakeholderId = searchParams.get("stakeholderid");
  const projectId = searchParams.get("projectid");
  const is_view = searchParams.get("is_view");
  const [selectedFile, setSelectedFile] = useState(null);
  const [data, setdata] = useState('');
  const [inputHistory, setinputHistory] = useState([]);
  const [FileError, setFileError] = useState(null)
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loader, setLoader] = useState(false)
  const [updatesuccess, setupdatesuccess] = useState(false)
  const [PopupMessage, setPopupMessage] = useState('')  
  const [isuploading, setisuploading] = useState(false)  
  const [AiLoader,setAiLoader] = useState(false)
  const getUser = async () => {
    try {
      const response = await axios.get(`/apiV1/stakeholder-inputs/${projectId}/${stakeholderId}/`);
      console.log(response.data);
      if (response.status == 200) {
        setdata(response.data.inputs[0])
        setinputHistory(response.data.inputs[0].history[0].inputed_text)
      }
    } catch (error) {
      console.error("Error generating project:", error.response ? error.response.data : error.message);
    }
  };
  useEffect(() => {
    getUser()
  }, [])
  const handleFileRemove = () => {
    setSelectedFile(null);
    setUploadProgress(0);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "application/pdf" || file.type === "text/plain" || file.name.endsWith(".docx")) {
        setSelectedFile(file);
        setFileError("");
      } else {
        setFileError("Invalid file type. Please upload a .docx, .pdf, or .txt file.");
        return;
      }
      setUploadProgress(0);
      setisuploading(true)
      const interval = setInterval(() => {
        setUploadProgress((prev) => {
          if (prev >= 100) {
            clearInterval(interval);
            handleApiCall(file);
            return 100;
          }
          return prev + 10;
        });
      }, 500);
    }
  };

  const handleApiCall = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await axios.post("/apiV1/upload-file/extract-text/", formData);
      if (response.status === 200) {
        setinputHistory((prevInputHistory) => prevInputHistory + "\n" + response.data.extracted_text);
        setisuploading(false)
      }
    } catch (error) {
      console.error("Error generating project:", error.response ? error.response.data : error.message);
    }
  };
  const onSubmit = async (data, isSubmitted) => {
    const formData = new FormData();
    formData.append('project', projectId);
    formData.append('inputed_text', inputHistory);
    formData.append('stakeholder', stakeholderId);
    formData.append('is_submitted', isSubmitted);

    if (selectedFile) {
      formData.append('file', selectedFile);
    }

    try {
      setLoader(true)
      const response = await axios.post("/apiV1/stakeholder-inputs/", formData);
      if (response.status === 200) {
        setLoader(false)
        if (isSubmitted === 1) {
          setPopupMessage("Submitted successfully!");
      } else if (isSubmitted === 0) {
          setPopupMessage("Saved successfully!");
      }
        setupdatesuccess(true)
        // navigate(`/managerview?id=${response.data.project}`);
      }
    } catch (error) {
      console.error("Error generating project:", error.response ? error.response.data : error.message);
      setLoader(false)
    }
  };

  const handleInputChange = (e) => {
    setinputHistory(e.target.value);
  };
  const handleSave = (e) => {
    e.preventDefault();
    handleSubmit((data) => onSubmit(data, 0))();
  };

  const handleSubmitClick = (e) => {
    e.preventDefault();
    handleSubmit((data) => onSubmit(data, 1))();
  };
  const hanldeAisuggestion = async () => {
    setAiLoader(true)
    try {
      let Data = {
        text: inputHistory
      }
      const response = await axios.post(`/apiV1/aisuggestion/`, Data);
      if (response.status == 200) {
         setAiLoader(false)
        setinputHistory(response.data.result)
      }
    } catch (error) {
      console.error("Error generating project:", error.response ? error.response.data : error.message);
       setAiLoader(false)

    }
  }
    const BCrumb = [
      {
          to: `/projectview?id=${projectId}`,
          title: 'Back',
      },
  ];
  return (
<> 
<PageContainer title="ManagerInput">
<Breadcrumb breadcrumbs={BCrumb} />
    <Grid
      style={{  padding: "10px 30px 0px 30px ", backgroundColor: "#fff", boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",margin:"16px 0px 0px 0px"}}  >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ width: "100%", display: 'flex', height: "85vh" }}>
          <Box sx={{mr:2}}>
          <Box sx={{ gap: 2 }}>
            <Box sx={{ mb: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, ml: 1 }}>
                Designation
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter Designation"
                value={data?.stakeholder_designation}
                disabled
                {...register('designation')}
                error={!!errors.designation}
                helperText={errors.designation?.message}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, ml: 1 }}>
                Email
              </Typography>
              <TextField
                fullWidth
                placeholder="manager@company.com"
                value={data?.stakeholder_email}
                disabled
                {...register('email')}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <label htmlFor="file-upload">
                <input
                  id="file-upload"
                  type="file"
                  hidden
                  onChange={handleFileChange}
                  disabled={is_view || loader ||isuploading}
                  accept=".pdf,.docx,.txt"
                />
                <Box
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: 1,
                    padding: '10px 16px',
                    backgroundColor: is_view || loader ||isuploading || AiLoader ? "#e0e0e0" : "#5a1bc2", 
                    color: is_view || loader ||isuploading ? "#acaeb0" : "#fff", 
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    cursor: 'pointer',
                  }}
                >
                  Attach File <IconFile />
                </Box>
              </label>
              <Typography>Upload a document to extract the text. </Typography>
              <Typography><span style={{ color: "red" }}>*</span>Accepted files:(doc,docx,pdf,word)</Typography>

            </Box>

            {selectedFile && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  backgroundColor: '#f5f5ff',
                  padding: '12px 16px',
                  borderRadius: '8px',
                  border: '1px solid #e0e0e0',
                  position: 'relative',
                  width: '100%',
                  maxWidth: '400px',
                  maxHeight: '52px',
                }}
              >
                <IconFile size={40} color="#7a7ae6" />
                <Box sx={{ flex: 1 }}>
                  <Typography variant="body2" sx={{ color: '#444', fontWeight: 500 }}>
                    {selectedFile.name}
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress}
                    sx={{
                      mt: 1,
                      height: '6px',
                      borderRadius: '3px',
                      backgroundColor: '#e6e6ff',
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: '#7a7ae6',
                      },
                    }}
                  />
                </Box>
                {/* <Typography
                  variant="body2"
                  sx={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: '#7a7ae6',
                    position: 'absolute',
                    right: 16,
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                >
                  {uploadProgress}%
                </Typography> */}
              </Box>
            )}

            {selectedFile && (
              <Box sx={{ mt: 2, ml: 2 }}>
                <IconTrash color="red" onClick={handleFileRemove} style={{ cursor: 'pointer' }} />
              </Box>
            )}
            <Box sx={{ alignItems: "center" }}>
              <Button
                variant="contained"
                size="small"
                fullWidth
                sx={{ gap: 1 }}
                disabled={is_view || loader ||isuploading|| AiLoader}
                onClick={handleSave}
              >
                    {loader ? (
                  <CircularProgress size={24} /> 
                ) : (
                  <>
                    Save <IconBookmark />
                  </>
                )}
              </Button>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Box sx={{ mb: 2 }}>

                {FileError && <Alert severity='error'>{FileError ? FileError : null}</Alert>}
              </Box>
              <Button variant="contained" fullWidth type="submit" sx={{ gap: 1 }} disabled={is_view || loader ||isuploading || AiLoader} onClick={handleSubmitClick} >
              {loader ? (
                  <CircularProgress size={24} /> 
                ) : (<>
                Submit <IconSend2 />
                </>
                )}
              </Button>
            </Box>
          </Box>
          </Box>
          <Box sx={{ mb: 2, width: "100%", }}>
            <Box sx={{ display: 'flex', mb: 1 ,justifyContent:"space-between"}}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: "#8069e5" }}>
                Provide Your Input
              </Typography>
              <Button
                onClick={hanldeAisuggestion}
                disabled={loader || AiLoader|| is_view}
                sx={{
                  // display: 'flex',
                  // alignItems: 'center',
                  // justifyContent: 'center',
                  backgroundColor: 'transparent',
                  color: '#7a5bf6',
                  fontWeight: 600,
                  textTransform: 'none',
                  boxShadow: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  padding: 0,
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: '#6747cc',
                  },
                }}
              >
                <IconWand style={{ fontSize: '18px', marginRight: '4px' }} /> AI Suggestion
              </Button>
            </Box>
            <div style={{ position: 'relative' }}>

              <textarea
                placeholder="Provide Your Input"
                value={inputHistory}
                rows={10}
                style={{
                  width: '100%',
                  height: '79vh',
                  borderRadius: '12px',
                  padding: '10px',
                  resize:"none",
                  fontSize: '16px',
                  border: '1px solid #ccc',
                  fontFamily: 'Arial, sans-serif',
                  // boxShadow: " #7a5bf6 0px 0px 0px 3px",
                  paddingRight: loader ? '40px' : '10px', outline: 'none',
                }}
                {...register('inputText', { required: 'Input text is required' })}
                onChange={handleInputChange}
                disabled={loader || is_view || AiLoader}
              />
              {AiLoader && (
              <div
                  style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 10,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                  }}
              >
                  <CircularProgress size={40} thickness={4} />
              </div>
          )}
            </div>

          </Box>
          {/* <Box sx={{ mt: 2, display: 'flex', justifyContent: "space-between" }}>
            <Box sx={{ mb: 2 }}>
              <label htmlFor="file-upload">
                <input
                  id="file-upload"
                  type="file"
                  hidden
                  onChange={handleFileChange}
                  disabled={is_view || loader}
                  accept=".pdf,.docx,.txt"
                />
                <Box
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    color: '#fff',
                    gap: 1,
                    padding: '10px 16px',
                    backgroundColor: '#5a1bc2',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    cursor: 'pointer',
                  }}
                >
                  Attach File <IconFile />
                </Box>
              </label>
              <Typography>Upload a document to extract the text. </Typography>
              <Typography><span style={{ color: "red" }}>*</span>Accepted files:(doc,docx,pdf,word,zip)</Typography>

            </Box>

            {selectedFile && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  backgroundColor: '#f5f5ff',
                  padding: '12px 16px',
                  borderRadius: '8px',
                  border: '1px solid #e0e0e0',
                  position: 'relative',
                  width: '100%',
                  maxWidth: '400px',
                  maxHeight: '52px',
                  ml: 3,
                }}
              >
                <IconFile size={40} color="#7a7ae6" />
                <Box sx={{ flex: 1 }}>
                  <Typography variant="body2" sx={{ color: '#444', fontWeight: 500 }}>
                    {selectedFile.name}
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress}
                    sx={{
                      mt: 1,
                      height: '6px',
                      borderRadius: '3px',
                      backgroundColor: '#e6e6ff',
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: '#7a7ae6',
                      },
                    }}
                  />
                </Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: '#7a7ae6',
                    position: 'absolute',
                    right: 16,
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                >
                  {uploadProgress}%
                </Typography>
              </Box>
            )}

            {selectedFile && (
              <Box sx={{ mt: 2, ml: 2 }}>
                <IconTrash color="red" onClick={handleFileRemove} style={{ cursor: 'pointer' }} />
              </Box>
            )}
            <Box sx={{ alignItems: "center" }}>

              <Button
                variant="contained"
                size="small"
                sx={{ gap: 1 }}
                disabled={is_view || loader}
                onClick={handleSave}
              >
                Save<IconBookmark />
              </Button>
            </Box>
          </Box> */}
          {/* <Box sx={{ mt: 2 }}>
            <Box sx={{ mb: 2 }}>

              {FileError && <Alert severity='error'>{FileError ? FileError : null}</Alert>}
            </Box>
            <Button variant="contained" fullWidth type="submit" sx={{ gap: 1 }} disabled={is_view || loader} onClick={handleSubmitClick} >
              Submit <IconSend2 />
            </Button>
          </Box> */}
        </Grid>
      </form>
    </Grid>
    <Dialog
                open={updatesuccess}
                onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                        setupdatesuccess(false);
                    }
                }}
                aria-labelledby="success-dialog-title"
                aria-describedby="success-dialog-description"
                maxWidth="sm"
                fullWidth
            >
                <DialogContent>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            padding: "16px",
                            gap: 2,
                        }}
                    >
                        <IconCircleCheck size={80} color="#7a5afc" />
                        <Box>
                            <Typography
                                variant="h5"
                                sx={{
                                    fontWeight: "600",
                                    fontSize: "2rem",
                                    color: "#7a5afc",
                                    marginBottom: "8px",
                                }}
                            >
                                Success!
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    fontWeight: "500",
                                    fontSize: "1rem",
                                    color: "textSecondary",
                                }}
                            >
                               Your input {PopupMessage}
                            </Typography>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        sx={{
                            backgroundColor: "#7a5afc",
                            color: "white",
                            "&:hover": {
                                backgroundColor: "#5d1ac0",
                            },
                            textTransform: "none",
                        }}
                        onClick={() => {
                            setupdatesuccess(false);
                            navigate(`/projectview?id=${projectId}`);
                        }}
                    >
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
            </PageContainer>
    </>
  )
}

export default Managerinput