import React from 'react';
import { Card, CardContent, Typography, Grid, TextField, Box, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { IconFingerprint } from '@tabler/icons-react';
import PageContainer from '../MainPages/PageContainer';
import Breadcrumb from '../MainPages/Breadcrumb';

const Billing = () => {
  const { register: register1, handleSubmit: handleSubmit1, formState: { errors: errors1 }, clearErrors: clearErrors1, reset: reset1, getValues: getValues1 } = useForm();
  const { register, handleSubmit, formState: { errors }, clearErrors, reset, getValues, trigger } = useForm();
  const NameOnCard = getValues("nameOnCard")
  const CardNumber = getValues("cardNumber")
  const Cvv = getValues("cvv")
  const Expire = getValues("expirationDate")
  const params = new URLSearchParams(window.location.search);
    const SelectedPlan = params.get("plan")
  const onSubmitBilling = (data) => {
    console.log('Billing Data:', data);
    alert('Billing Information saved successfully!');
  };

  const onSubmit = (data) => {
    console.log('Payment Data:', data);
    alert('Payment Details saved successfully!');
  };
  const BCrumb = [
    {
      to: '/plans',
      title: 'Plans',
    }
  ];
  return (
    <>
      <PageContainer title="BA Tool | Billing">
        <Breadcrumb breadcrumbs={BCrumb} />
        <Card
          sx={{
            padding: 4,
            margin: "30px 0px 0px 0px",
            boxShadow: 3,
          }}
        >
          <Typography sx={{ml:2,fontSize:20}}>Selected Plan : {SelectedPlan}</Typography>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} sx={{ p: 2 }}>
                <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
                  <Typography variant="h6" sx={{ marginBottom: 2, padding: 2, fontWeight: 550 }}>
                    Billing Information
                  </Typography>
                  <form onSubmit={handleSubmit1(onSubmitBilling)}>
                    <Grid container spacing={3} sx={{ p: 3 }}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Name"
                          placeholder="Jordan Smith"
                          {...register1('name', {
                            required: 'Name is required',
                          })}
                          error={!!errors1.name}
                          helperText={errors1.name ? errors1.name.message : ''}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Email Address"
                          placeholder="jordansmith@mail.com"
                          {...register1('email', {
                            required: 'Email is required',
                            pattern: {
                              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                              message: 'Invalid email address',
                            },
                          })}
                          error={!!errors1.email}
                          helperText={errors1.email ? errors1.email.message : ''}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Phone Number"
                          placeholder="(123) 456-7890"
                          {...register1('phone', {
                            required: 'Phone number is required',
                            pattern: {
                              value: /^\(\d{3}\) \d{3}-\d{4}$/,
                              message: 'Invalid phone number format',
                            },
                          })}
                          error={!!errors1.phone}
                          helperText={errors1.phone ? errors1.phone.message : ''}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Country"
                          placeholder="Country"
                          {...register1('country', {
                            required: 'Country is required',
                          })}
                          error={!!errors1.country}
                          helperText={errors1.country ? errors1.country.message : ''}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="State"
                          placeholder="State"
                          {...register1('state', {
                            required: 'State is required',
                          })}
                          error={!!errors1.state}
                          helperText={errors1.state ? errors1.state.message : ''}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="City"
                          placeholder="City"
                          {...register1('City', {
                            required: 'City is required',
                          })}
                          error={!!errors1.City}
                          helperText={errors1.City ? errors1.City.message : ''}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Address"
                          placeholder="123 Main St"
                          {...register1('address', {
                            required: 'Address is required',
                          })}
                          error={!!errors1.address}
                          helperText={errors1.address ? errors1.address.message : ''}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Postal Code"
                          placeholder="12345"
                          {...register1('postalCode', {
                            required: 'Postal code is required',
                            pattern: {
                              value: /^\d{5}$/,
                              message: 'Postal code must be 5 digits',
                            },
                          })}
                          error={!!errors1.postalCode}
                          helperText={errors1.postalCode ? errors1.postalCode.message : ''}
                        />
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        m: 2,
                        gap: 2,
                      }}
                    >
                      <Button variant="outlined" color="inherit" onClick={() => reset1()} sx={{ backgroundColor: "crimson" }}>
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        type="submit"
                        color='primary'
                      >
                        Save
                      </Button>
                    </Box>
                  </form>
                </Card>
              </Grid>

              <Grid item xs={12} md={6} sx={{ p: 3 }}>
                <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
                  <Typography variant="h6" sx={{ marginBottom: 2, padding: 2, fontWeight: 550 }}>
                    Payment Details
                  </Typography>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={3} sx={{ p: 3 }}>
                      {/* Name on Card */}
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Name on Card"
                          placeholder="Jordan Smith"
                          {...register("nameOnCard", {
                            required: "Name is required",
                            pattern: {
                              value: /^[A-Za-z\s]+$/,
                              message: "Name must contain only letters",
                            },
                          })}
                          error={!!errors.nameOnCard}
                          helperText={errors.nameOnCard?.message}
                          onKeyUp={() => trigger("nameOnCard")}
                        />
                      </Grid>

                      {/* Card Number */}
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Card Number"
                          placeholder="1234 1234 1234 1234"
                          {...register("cardNumber", {
                            required: "Card number is required",
                            pattern: {
                              value: /^\d{12}$/,
                              message: "Card number must be 12 digits",
                            },
                          })}
                          error={!!errors.cardNumber}
                          helperText={errors.cardNumber?.message}
                          onKeyUp={() => trigger("cardNumber")}
                          inputProps={{ maxLength: 16 }}
                        />
                      </Grid>

                      {/* Expiration Date */}
                      <Grid item xs={6} sm={4}>
                        <TextField
                          fullWidth
                          label="Expiration Date"
                          placeholder="MM/YY"
                          {...register("expirationDate", {
                            required: "Expiration date is required",
                            pattern: {
                              value: /^(0[1-9]|1[0-2])\/\d{2}$/,
                              message: "Invalid format (MM/YY)",
                            },
                          })}
                          error={!!errors.expirationDate}
                          helperText={errors.expirationDate?.message}
                          onKeyUp={() => trigger("expirationDate")}
                        />
                      </Grid>

                      {/* CVV */}
                      <Grid item xs={6} sm={4}>
                        <TextField
                          fullWidth
                          label="CVV/CVC"
                          placeholder="XXX"
                          {...register("cvv", {
                            required: "CVV is required",
                            pattern: {
                              value: /^\d{3}$/,
                              message: "CVV must be 3 digits",
                            },
                          })}
                          error={!!errors.cvv}
                          helperText={errors.cvv?.message}
                          onKeyUp={() => trigger("cvv")}
                          inputProps={{ maxLength: 3 }}
                        />
                      </Grid>

                      {/* Card Preview */}
                      <Grid item xs={12} sm={6}>
                        <Box sx={{ maxHeight: "500px", maxWidth: "600px" }}>
                          <Card sx={{ borderRadius: 2, boxShadow: 3, position: "relative" }}>
                            <CardContent
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                padding: 2,
                                background: "radial-gradient(circle, rgba(170, 168, 168, 0.1) 10%, rgba(107, 105, 105, 0.2) 50%, rgba(151, 150, 150, 0.7) 80%)",
                                position: "relative",
                                borderRadius: 2,
                                overflow: "hidden",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                  {NameOnCard ? NameOnCard : "Your Name"}
                                </Typography>
                                <IconFingerprint />
                              </Box>

                              <Box sx={{ marginTop: 2 }}>
                                <Typography variant="body2" sx={{ fontSize: 10 }}>
                                  Card Number
                                </Typography>
                                <Typography variant="h5" sx={{ letterSpacing: "0.1em" }}>
                                  **** **** **** {CardNumber ? CardNumber.slice(-4) : ""}
                                </Typography>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: 2,
                                }}
                              >
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                  <Typography variant="body2" sx={{ fontSize: 8 }}>
                                    Expiry Date
                                  </Typography>
                                  <Typography variant="h6">
                                    {Expire ? Expire : "MM/YY"}
                                  </Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                  <Typography variant="body2" sx={{ fontSize: 8 }}>
                                    CVV
                                  </Typography>
                                  <Typography variant="h6">
                                    {Cvv ? Cvv : "123"}
                                  </Typography>
                                </Box>
                              </Box>
                            </CardContent>
                          </Card>
                        </Box>
                      </Grid>
                    </Grid>

                    {/* Buttons */}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        m: 2,
                        gap: 2,
                      }}>
                      <Button variant="outlined" color="secondary" onClick={() => reset()} sx={{ backgroundColor: "crimson" }}>
                        Cancel
                      </Button>
                      <Button variant="contained" color="primary" type="submit">
                        Save
                      </Button>
                    </Box>
                  </form>
                </Card>
              </Grid>

            </Grid>
          </CardContent>
        </Card>
      </PageContainer>
    </>
  );
};

export default Billing;
