import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Card, CardContent, Button, Avatar, ToggleButtonGroup, ToggleButton, CircularProgress, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { IconCircleCheck, IconDots, IconDotsVertical, IconEye, IconTrash, IconX } from '@tabler/icons-react';
import avatar1 from '../../Images/user-1.jpg'
import avatar2 from '../../Images/user-2.jpg'
import avatar3 from '../../Images/user-3.jpg'
import avatar4 from '../../Images/user-4.jpg'
import Nodata from '../../Images/Nodata.avif'
import PageContainer from './PageContainer';
const MyProjects = () => {
    const [projects, setprojects] = useState([]);
    const userInfo = JSON.parse(sessionStorage.getItem('user-info'));
    const [loading, setLoading] = useState(true);
    const groupAvatars = [avatar1, avatar2, avatar3, avatar4];
    const navigate = useNavigate();
    const reactcolors = ['#fed466', '#6bc8f3', '#8cd6bb', '#fa9962', '#e875ba']
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [alignment, setAlignment] = useState('Assigned');
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [Successpopup, setSuccesspopup] = useState(false);
    const [Deletepopup, setDeletepopup] = useState(false);


    const getProjects = async () => {
        try {
            setLoading(true)
            const response = await axios.get('apiV1/projects/')
            if (response.status == 200) {
                console.log(response);
                setprojects(response.data)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }
    useEffect(() => {
        getProjects()
    }, [])


    useEffect(() => {
        if (alignment === 'Assigned') {
            const assignedProjects = projects.filter(project => project.can_access === true);
            setFilteredProjects(assignedProjects);
        } else {
            const unassignedProjects = projects.filter(project => project.can_access === false);
            setFilteredProjects(unassignedProjects);
        }
    }, [projects, alignment]);

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedId, setSelectedId] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setSelectedId(id);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = (data) => {
        navigate(`/newproject?id=${selectedId}&is_edit=true`)
        handleClose();
    };

    const handleDelete = async(data) => {
        try {
            const response = await axios.delete(`/apiV1/edit-project/${selectedId}/`)
            if (response.status == 200) {
               console.log(response);
               setSuccesspopup(true)
               setDeletepopup(false)
               getProjects()
               handleClose()
            }
        } catch (error) {
            console.log(error)
        }
    };
    return (
        <><PageContainer title="BA Tool | My Projects">
        <Card sx={{ display: 'flex', flexDirection: 'column', margin: "0px 0px 0px 9px", justifyContent: "space-between" ,overflowY:"hidden"}}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mx: 2 ,mb:1}}>
                <Typography sx={{fontSize:30,ml:5,mt:1}}>Projects</Typography>
                {userInfo.is_admin === true ?
                <Button
                sx={{
                    position: 'relative',
                    top: 5,
                    maxHeight: "45px",
                    maxWidth: '200px',
                    marginLeft: 'auto',
                }}
                onClick={() => navigate('/newproject')}
            >
                Create Project
            </Button>:null}
                {/* {userInfo.is_admin === false ?
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                    >
                        <ToggleButton
                            value="Assigned"
                            sx={{
                                borderRadius: "10px 0px 0px 10px", maxHeight: "45px", mt: 1,
                                backgroundColor: alignment === 'Assigned' ? '#5a1bc3' : 'transparent',
                                '&.Mui-selected': { backgroundColor: '#5a1bc3', color: "#fff" },
                                '&:hover': {
                                    backgroundColor: alignment === 'Assigned' ? '#5760c7' : '#8c93e5',
                                    color: "#000"
                                }
                            }} >
                            Assigned
                        </ToggleButton>
                        <ToggleButton
                            value="Unassigned"
                            sx={{
                                borderRadius: "0px 10px 10px 0px", maxHeight: "45px", mt: 1,
                                backgroundColor: alignment === 'Unassigned' ? '#5a1bc3' : 'transparent',
                                '&.Mui-selected': { backgroundColor: '#5a1bc3', color: "#fff" },
                                '&:hover': {
                                    backgroundColor: alignment === 'Unassigned' ? '#5760c7' : '#8c93e5',
                                    color: "#000"
                                }
                            }}>
                            Unassigned
                        </ToggleButton>
                    </ToggleButtonGroup>
                    : <Button
                        sx={{
                            position: 'relative',
                            top: 5,
                            maxHeight: "45px",
                            maxWidth: '150px',
                            marginLeft: 'auto',
                        }}
                        onClick={() => navigate('/newproject')}
                    >
                        Add Project
                    </Button>} */}
            </Box>

            <CardContent sx={{overflowY: "auto"}}>
                <Grid container spacing={7} sx={{ p: 5 }}>
                    {loading ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '250px',
                                overflowY:"auto"
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        projects.length > 0 ? (
                            <Grid container spacing={3} sx={{ padding: { xs: 2, sm: 3, md: 6 } }}>
                                {projects.map((project, index) => (
                                    <Grid 
                                        item 
                                        xs={12} sm={6} md={4} lg={3} 
                                        key={index}
                                    >
                                        <Card
                                            sx={{
                                                maxWidth: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                backgroundColor: "#f6f7fb",
                                                transition: 'box-shadow 0.3s ease, transform 0.3s ease',
                                                boxShadow: hoveredIndex === index
                                                    ? "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px"
                                                    : 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                                '&:hover': {
                                                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                                    backgroundColor: "#fff",
                                                    transform: 'translateY(-5px)',
                                                }
                                            }}
                                            onMouseEnter={() => setHoveredIndex(index)}
                                            onMouseLeave={() => setHoveredIndex(null)}
                                        >
                                            <CardContent sx={{ flexGrow: 1 }}>
                                                <Box sx={{ display: 'flex', gap: 2 }}>
                                                    <Box
                                                        sx={{
                                                            width: 60,
                                                            height: 60,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            borderRadius: '4px',
                                                            backgroundColor: reactcolors[index % reactcolors.length],
                                                            color: 'white',
                                                            fontSize: '30px',
                                                            fontWeight: 'bold',
                                                            flexShrink: 0,
                                                        }}
                                                    >
                                                        {project.name.charAt(0).toUpperCase()}
                                                    </Box>
                                                    <Box sx={{ flexGrow: 1, minWidth: 0 }}>
                                                        <Typography
                                                            variant="h6"
                                                            sx={{
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                            }}
                                                        >
                                                            {project.name}
                                                        </Typography>
                                                        <Box sx={{ display: 'flex', marginLeft: '10px', position: 'relative', flexWrap: 'wrap' }}>
                                                            {groupAvatars.slice(0, Math.min(project.count, 4)).map((avatar, idx) => (
                                                                <Avatar
                                                                    key={idx}
                                                                    sx={{
                                                                        width: 30,
                                                                        height: 30,
                                                                        marginLeft: idx === 0 ? 0 : '-10px',
                                                                        border: '2px solid white',
                                                                    }}
                                                                    alt={`Avatar ${idx + 1}`}
                                                                    src={avatar}
                                                                />
                                                            ))}
                                                            {project.count > 4 && (
                                                                <Avatar
                                                                    sx={{
                                                                        width: 30,
                                                                        height: 30,
                                                                        marginLeft: '-10px',
                                                                        border: '2px solid white',
                                                                        backgroundColor: '#5a1bc2',
                                                                        color: 'white',
                                                                        fontSize: '14px',
                                                                    }}
                                                                >
                                                                    +{project.count - 4}
                                                                </Avatar>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                    {(userInfo.is_admin || userInfo.is_manager) && (
                                                        <Box>
                                                            <IconDotsVertical onClick={(event) => handleClick(event, project.id)} />
                                                            <Menu
                                                                sx={{
                                                                    '& .MuiPaper-root': {
                                                                        boxShadow: '#d9d9d9 0px 1px 2px 1px',
                                                                    },
                                                                }}
                                                                anchorEl={anchorEl}
                                                                open={open}
                                                                onClose={handleClose}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                            >
                                                                <MenuItem onClick={handleEdit}>Edit</MenuItem>
                                                                <MenuItem onClick={() => { setDeletepopup(true); handleClose(); }}>Delete</MenuItem>
                                                            </Menu>
                                                        </Box>
                                                    )}
                                                </Box>
                                                <Typography
                                                    variant="body2"
                                                    mt={2}
                                                    sx={{
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: 3,
                                                        WebkitBoxOrient: 'vertical',
                                                    }}
                                                >
                                                    {project.description}
                                                </Typography>
                                            </CardContent>
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{
                                                    borderTop: '2px dashed #c0c1c4',
                                                    padding: "10px 30px 10px 30px"
                                                }}
                                            >
                                                {project.can_access ? (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}
                                                        fullWidth
                                                        onClick={() => { navigate(`/projectview?id=${project.id}`); }}
                                                    >
                                                        View <IconEye />
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}
                                                        fullWidth
                                                        onClick={() => { alert("You are not allowed to perform this action"); }}
                                                        disabled
                                                    >
                                                        View <IconEye />
                                                    </Button>
                                                )}
                                            </Box>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : null
                         (
                            <Typography sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                              <img src={Nodata}/>
                            </Typography>
                        )
                    )}
                </Grid>
            </CardContent>
        </Card>
        </PageContainer>
        <Dialog
                open={Deletepopup}
                onClose={() => setDeletepopup(false)}
                aria-labelledby="confirm-delete-dialog-title"
                aria-describedby="confirm-delete-dialog-description"
                PaperProps={{
                    sx: { borderRadius: '16px', padding: '16px' },
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box />
                    <IconButton size="small" onClick={() => setDeletepopup(false)}>
                        <IconX />
                    </IconButton>
                </Box>
                <DialogTitle id="confirm-delete-dialog-title" align="center" sx={{ marginTop: -3 }}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Box
                            sx={{
                                width: 60,
                                height: 60,
                                backgroundColor: '#fdecea',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '50%',
                                marginBottom: 2,
                            }}
                        >
                            <IconTrash size={32} color="#e57373" />
                        </Box>
                    </Box>
                    <Typography variant="h6" fontWeight="bold">
                        Confirm Delete
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography id="confirm-delete-dialog-description" align="center">
                        Are you sure you want to delete Project? This action cannot be undone.
                    </Typography>
                </DialogContent>
                <DialogActions
                    sx={{ display: 'flex', justifyContent: 'center', gap: 2, marginBottom: 1 }}
                >
                    <Button
                        variant="outlined"
                        color="inherit"
                        sx={{
                            width: 120,
                            borderColor: '#e0e0e0',
                        }}
                        onClick={() => setDeletepopup(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        sx={{ width: 120 }}
                        onClick={handleDelete}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        <Dialog
                open={Successpopup}
                onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                        setSuccesspopup(false);
                    }
                }}
                aria-labelledby="success-dialog-title"
                aria-describedby="success-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    sx: { borderRadius: '16px', padding: '16px' },
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box />
                    <IconButton size="small" onClick={() => setSuccesspopup(false)}>
                        <IconX />
                    </IconButton>
                </Box>
                <DialogTitle id="confirm-delete-dialog-title" align="center" sx={{ marginTop: -3 }}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                textAlign: "center",
                                padding: "16px",
                                gap: 2,
                            }}
                        >
                            <IconCircleCheck size={80} color="#7a5afc" />
                            <Box>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontWeight: "600",
                                        fontSize: "2rem",
                                        color: "#7a5afc",
                                        marginBottom: "8px",
                                    }}
                                >
                                    Success!
                                </Typography>
                                <Typography id="confirm-delete-dialog-description" align="center">
                                    Project has been Deleted Succesfully
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogActions
                    sx={{ display: 'flex', justifyContent: 'center', gap: 2, marginBottom: 1 }}
                >
                    <Button
                        variant="outlined"
                        color="inherit"
                        sx={{
                            width: 120,
                            borderColor: '#e0e0e0',
                        }}
                        onClick={() => setSuccesspopup(false)}
                    >
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MyProjects;
