import { Alert, Avatar, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import { Box, Grid, margin, Stack } from '@mui/system';
import { IconEdit, IconEye, IconBubbleText, IconFileText, IconGitPullRequest, IconX, IconSend2, IconRestore } from '@tabler/icons-react';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../MainPages/Breadcrumb';
import PageContainer from '../MainPages/PageContainer';
import Nodata from '../../Images/Nodata.avif'
const ManagerView = () => {
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [data, setData] = useState('')
    const [Loader, setLoader] = useState(false)
    const [StakeholderData, setStakeholderData] = useState([])
    const [CommentDialog, setCommentDialog] = useState(false)
    const [BackendError, setBackendError] = useState('')
    const [CorrectionReq, setCorrectionReq] = useState(false)
    const [correctionText, setCorrectionText] = useState("");
    const [commenttext, setcommenttext] = useState("");
    const [error, setError] = useState(false);
    const [OpenMasterDialog, setOpenMasterDialog] = useState(false)
    const [requestStakeid, setrequestStakeid] = useState('')
    const [commentStakeId, setcommentStakeId] = useState('')
    const [CommentsHistory, setCommentsHistory] = useState([])
    const [RequestHistory, setRequestHistory] = useState([])
    const [RequestData, setRequestData] = useState('')
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");
    const [ManagerData, setManagerData] = useState([])
    const userInfo = JSON.parse(sessionStorage.getItem('user-info')); 
    const idfromurl = params.get("commented_id") ? params.get("commented_id") : "";
    useEffect(() => {
        // const previousId = localStorage.getItem("lastCommentedId");

        if (idfromurl !== "") {
            // localStorage.setItem("lastCommentedId", idfromurl);
            setCommentDialog(true);
            getCommets(idfromurl);
        }
        // return () => {
        //     localStorage.removeItem("lastCommentedId");
        // };
    }, [idfromurl]);

    const getUser = async () => {
        try {
            setLoader(true)
            const response = await axios.get(`/apiV1/projects/${id}/`);
            console.log(response.data);
            if (response.status == 200) {
                setLoader(false)
                setData(response.data)
                // setStakeholderData(response.data.members);
                setManagerData(response.data.members.filter(member => member.is_manager == true))
                setStakeholderData(response.data.members.filter(member => member.is_manager !== true));

            }
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
            setLoader(false)
        }
    };
    useEffect(() => {
        getUser()
    }, [])
    const handleSend = async () => {
        if (correctionText.trim() === "") {
            setError(true);
            return;
        }

        const requestData = {
            input_id: requestStakeid,
            approval_status: "pending",
            comment: correctionText,
            project_id: id
        };

        try {
            const response = await axios.post("/apiV1/raise-request/", requestData);
            if (response.status === 200) {
                setError(false);
                setCorrectionReq(false)
                setCorrectionText('')
            }
        } catch (error) {
            console.error("Error raising request:", error.response ? error.response.data : error.message);
            setBackendError(error.response.data.detail);

        }
    };
    const handleCommentChange = (event) => {
        setcommenttext(event.target.value);
        if (event.target.value.trim() !== "") {
            setError(false);
        }
    };
    const handleTextChange = (event) => {
        setCorrectionText(event.target.value);
        if (event.target.value.trim() !== "") {
            setError(false);
        }
    };
    const getCommets = async (data) => {
        try {
            const response = await axios.get(`/apiV1/comments/comments/?project_id=${id}&commented_on=${data}`);
            console.log(response);

            if (response.status == 200) {
                setCommentsHistory(response.data.comments)
            }
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
        }
    };
    const getRequest = async (data) => {
        try {
            const response = await axios.get(`/apiV1/requests-for-project/?project_id=${id}&input_id=${data}`);
            if (response.status == 200) {
                console.log(response, 'response');
                setRequestData(response.data[0])
                const allComments = response.data.flatMap(item => item.comments);
                setRequestHistory(allComments);
            }
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
        }
    };
    const handlePostComment = async () => {
        if (commenttext.trim() === "") {
            setError(true);
            return;
        }

        const requestData = {
            commented_on: commentStakeId ?commentStakeId :idfromurl,
            comment: commenttext,
            project: id
        };

        try {
            const response = await axios.post("/apiV1/comments/", requestData);
            console.log(response, 'responseresponse');
            if (response.status === 201) {
                setcommenttext('')
                setCommentDialog(false);
                setError(false);
            }
        } catch (error) {
            console.error("Error raising request:", error.response ? error.response.data : error.message);
            setBackendError(error.response.data.detail);

        }
    };
    const handleApproval = async (data) => {
        const requestData = {
            approval_status: data
        };

        try {
            const response = await axios.put(`/apiV1/raise-request/${RequestData.id}/update-approval/`, requestData);
            console.log(response, 'responseresponse');
            if (response.status === 200) {
                setCorrectionReq(false)
            }
        } catch (error) {
            console.error("Error raising request:", error.response ? error.response.data : error.message);
            setBackendError(error.response.data.detail);

        }
    };
    const onSubmit = async (data) => {
        const requestData = {
            master_input_name: data.name
        };
        setLoader(true)
        try {
            const response = await axios.post(`/apiV1/project/${id}/generate-master-input/`, requestData);
            if (response.status === 201) {
                setOpenMasterDialog(false)
                reset()
                navigate(`/masterScopeview?id=${id}&name=${data.name}`)
                setLoader(false)
            }
        } catch (error) {
            console.error("Error raising request:", error.response ? error.response.data : error.message);
            setBackendError(error.response.data.message);
            setLoader(false)
        }
    };
    const hasSubmitted = StakeholderData.some(item => item.is_submitted);
    const BCrumb = [
        {
            to: '/Dashboard',
            title: 'Dashboard',
        },
    ];
    const [show404, setShow404] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
        setShow404(true);
        }, 3000); 
        return () => clearTimeout(timer);
    }, []);
    const handleClosePopup = (setDialogState, paramToRemove) => {
        const params = new URLSearchParams(window.location.search);
        if (paramToRemove) {
            params.delete(paramToRemove); 
        }
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        window.history.replaceState(null, "", newUrl); 
        setDialogState(false); 
    };
    return (
        <>
         <PageContainer title="Projectview" description="this is Cards page">
        <Breadcrumb breadcrumbs={BCrumb} />
            <Grid container sx={{ justifyContent: "center" }} style={{ padding: "30px", backgroundColor: "#fff", boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",margin:"16px 0px 0px 0px"}}  >
                {data.can_access === true ?
                    <Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box sx={{ display: "flex", gap: 2, padding: "10px 15px 2px 13px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px", bgcolor: "#f2eefd", borderRadius: "10px" }}>
                                <Box sx={{ mb: 1 }}>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                            Designation:
                                        </Typography>
                                        <TextField
                                            disabled
                                            fullWidth
                                            placeholder="Enter Designation"
                                            value={data.is_manager}
                                            sx={{
                                                height: '2.7rem',
                                                '& .MuiInputBase-root': {
                                                    height: '100%',
                                                    fontSize: '1rem',
                                                },
                                            }}
                                        />
                                    </Stack>
                                </Box>
                                <Box sx={{ mb: 1 }}>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                            Email:
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            placeholder="Enter Your email"
                                            disabled
                                            value={data?.manager_email}
                                            sx={{
                                                height: '2.7rem',
                                                '& .MuiInputBase-root': {
                                                    height: '100%',
                                                    fontSize: '1rem',
                                                },
                                            }}
                                        />
                                    </Stack>
                                </Box>
                                {/* {data.is_manager_submitted === true ? <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ display: 'flex', alignItems: 'center', gap: 1, maxHeight: '40px', }}
                                    onClick={() => navigate(`/managerinput?stakeholderid=${data.manager_id}&projectid=${data.id}&is_view=true`)}
                                >
                                    View <IconEye />
                                </Button> : null} */}
                                 {ManagerData[0]?.can_edit === true ? 
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ display: 'flex', alignItems: 'center', gap: 1, maxHeight: '40px', }}
                                    onClick={() => navigate(`/managerinput?stakeholderid=${data.manager_id}&projectid=${data.id}`)}
                                >
                                    Edit <IconEdit />
                                </Button> 
                                 : null} 
                                 {data.is_manager_or_admin === false ?                                 
                                 <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ display: 'flex', alignItems: 'center', gap: 1, maxHeight: '40px', }}
                                    onClick={() => navigate(`/managerinput?stakeholderid=${data.manager_id}&projectid=${id}&is_view=true`)}
                                >
                                    View <IconEye />
                                </Button> :null}
                                 <Button
                                        variant="contained"
                                        color="secondary"
                                        sx={{ display: "flex", alignItems: "center", gap: 1, maxHeight: "40px" }}
                                        onClick={() => { setCommentDialog(true); setcommentStakeId(data.manager_id); getCommets(data.manager_id) }}
                                    >
                                        Comment <IconBubbleText />
                                    </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ pt: 2 }}>
                            {StakeholderData.map((item, index) => (
                                <Box sx={{ display: "flex", gap: 2, padding: "10px" }}>
                                    <Box sx={{ mb: 1 }}>
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                                Designation:
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                disabled
                                                placeholder="Enter Designation"
                                                value={item.designation}
                                                sx={{
                                                    height: "2.7rem",
                                                    "& .MuiInputBase-root": {
                                                        height: "100%",
                                                        fontSize: "1rem",
                                                    },
                                                }}
                                            />
                                        </Stack>
                                    </Box>
                                    <Box sx={{ mb: 1 }}>
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                                Email:
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                placeholder="Enter Email"
                                                disabled
                                                value={item.email}
                                                sx={{
                                                    height: "2.7rem",
                                                    "& .MuiInputBase-root": {
                                                        height: "100%",
                                                        fontSize: "1rem",
                                                    },
                                                }}
                                            />
                                        </Stack>
                                    </Box>
                                    <Box sx={{ display: "flex", gap: 2 }}>
                                        {item.can_edit === false ? <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{ display: "flex", alignItems: "center", gap: 1, maxHeight: "40px" }}
                                            onClick={() => navigate(`/managerinput?stakeholderid=${item.id}&projectid=${data.id}&is_view=true`)}
                                        >
                                            View <IconEye />
                                        </Button> : null}
                                        {item.can_edit === true ? <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{ display: "flex", alignItems: "center", gap: 1, maxHeight: "40px" }}
                                            onClick={() => navigate(`/managerinput?stakeholderid=${item.id}&projectid=${data.id}`)}
                                        >
                                            Edit <IconEdit />
                                        </Button> : null}
                                        {/* {item.is_submitted === false ?  */}
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            sx={{ display: "flex", alignItems: "center", gap: 1, maxHeight: "40px" }}
                                            onClick={() => { setCommentDialog(true); setcommentStakeId(item.id); getCommets(item.id) }}
                                        >
                                            Comment <IconBubbleText />
                                        </Button> 
                                        {/* : null} */}
                                        {item.is_submitted === true && item.can_see_req === true ? <Button
                                            variant="contained"
                                            color="secondary"
                                            sx={{ display: "flex", alignItems: "center", gap: 1, maxHeight: "40px" }}
                                            onClick={() => { setrequestStakeid(item.id); setCorrectionReq(true); getRequest(item.id) }} >
                                            Correction <IconGitPullRequest />
                                        </Button> : null
                                        }
                                    </Box>
                                </Box>))}

                            {/* {showTextArea && (
                            <Box sx={{ mt: 2 }}>
                                <textarea
                                    placeholder="Provide Your Input"
                                    value={correctionText}
                                    onChange={handleTextChange}
                                    rows={10}
                                    style={{
                                        width: '100%',
                                        height: '300px',
                                        borderRadius: '12px',
                                        padding: '10px',
                                        fontSize: '16px',
                                        border: '1px solid #ebe7fe',
                                    }}
                                />
                                {error && (
                                    <Typography variant="body2" sx={{ color: 'red', mt: 1 }}>
                                        Please provide your input before submitting.
                                    </Typography>
                                )}
                                {BackendError && <Alert severity='error'>{BackendError ? BackendError : ''}</Alert>}
                                <Button
                                    variant="contained"
                                    sx={{ mt: 2, bgcolor: "red" }}
                                    onClick={() => { setShowTextArea(false); setError(''); setCorrectionText('') }}
                                >
                                    Cancel <IconX />
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 2, ml: 2 }}
                                    onClick={handleSend}
                                >
                                    Send <IconSend />
                                </Button>

                            </Box>
                        )} */}
                        </Grid>
                        <Grid  item xs={12} sm={12} md={12} lg={12}  sx={{ pt: 2, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                           {data.is_manager_or_admin ? <Box>
                            {!data.is_master_input_generated   ? (
                                    (data.can_generate === false) ? (
                                        <Tooltip title="Please provide at least one input to generate a master input." arrow>
                                            <span>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ gap: 2 }}
                                                    onClick={() => setOpenMasterDialog(true)}
                                                    disabled
                                                >
                                                    <IconFileText /> Generate Master Input
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{ gap: 2 }}
                                            onClick={() => setOpenMasterDialog(true)}
                                        >
                                            <IconFileText /> Generate Master Input
                                        </Button>
                                    )
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ gap: 2 }}
                                        onClick={() => setOpenMasterDialog(true)}
                                    >
                                        <IconRestore /> Re-create Master Input
                                    </Button>
                                )}
                                </Box>:null}
                            <Box>
                            {data.is_master_input_generated === true ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ gap: 2 }}
                                    onClick={() => navigate(`/masterScopeview?id=${id}&is_view=true`)}
                                >
                                    View Master Input <IconEye />
                                </Button>
                            ) : null}
                            </Box>
                        </Grid>



                    </Grid> : <div style={{ textAlign: 'center', marginTop: '3rem' }}>
                        {!show404 ? (
                            <CircularProgress />
                        ) : (
                            <Typography sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <img src={Nodata}/>
                          </Typography>
                        )}
                    </div>}
            </Grid>
            {/* ==================Dilogs===================== */}

            <Dialog open={CorrectionReq} onClose={() => setCorrectionReq(false)} fullWidth maxWidth="sm">
                <DialogTitle>
                    Request For Correction
                    <IconButton
                        onClick={() => setCorrectionReq(false)}
                        style={{ position: "absolute", right: 8, top: 8 }}
                    >
                        <IconX />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box display="flex" flexDirection="column" gap={2} p={2}>
                    {RequestHistory.map((message) => (
                                <>
                                    <Box
                                        key={message.id}
                                        display="flex"
                                        justifyContent={userInfo.email === message.texted_by ? "flex-end" : "flex-start"}
                                        alignItems="center"
                                        gap={2}
                                    >
                                        {userInfo.email !== message.texted_by && (
                                            <Avatar sx={{ backgroundColor: "blue" }}>
                                                {message.texted_by.charAt(0).toUpperCase()}
                                            </Avatar>
                                        )}
                                        <Box
                                            sx={{
                                                backgroundColor: userInfo.email === message.texted_by ? "#e3f2fd" : "#f0f0f0",
                                                color: userInfo.email === message.texted_by ? "#0d47a1" : "#000",
                                                borderRadius: 2,
                                                padding: "8px 16px",
                                                maxWidth: "70%",
                                            }}
                                        >
                                            <Typography>{message.text_message}</Typography>
                                        </Box>
                                        {userInfo.email === message.texted_by && (
                                            <Avatar>{message.texted_by.charAt(0).toUpperCase()}</Avatar>
                                        )}
                                    </Box>
                                    {userInfo.email !== message.texted_by && (
                                        <Box sx={{ width: "100%" }}>
                                            <Typography sx={{ fontSize: "12px", marginTop: 1 }}>From: {message.texted_by}</Typography>
                                        </Box>
                                    )}
                                </>
                            ))}

                    </Box>
                </DialogContent>
                <DialogActions sx={{ flexDirection: "column", alignItems: "stretch", gap: 2, p: 3 }}>
                    <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 600, ml: 1 }}
                    >
                        Comment
                    </Typography>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <textarea
                            placeholder="Add Your Comment Here......"
                            rows={10}
                            value={correctionText}
                            onChange={handleTextChange}
                            style={{
                                width: '100%',
                                height: '150px',
                                borderRadius: '12px',
                                padding: '10px',
                                fontSize: '16px',
                                border: '1px solid #ccc',
                                resize: 'vertical',
                                paddingRight: '50px',
                            }}
                        />
                        <Button
                            style={{
                                position: 'absolute',
                                right: '10px',
                                bottom: '10px',
                                height: '30px',
                                borderRadius: '8px',
                                backgroundColor: '#7a5af9',
                                color: '#fff',
                                border: 'none',
                                cursor: 'pointer',
                                padding: '0 10px',
                            }}
                            onClick={() => handleSend()}
                        >
                            <IconSend2 />
                        </Button>
                    </div>


                   {userInfo.is_manager === true || userInfo.is_admin === true ?<> <Divider />
                    <Box display="flex" justifyContent="end" sx={{ gap: 2 }}>
                        <Button variant="contained" sx={{ backgroundColor: '#6dc308' }} onClick={() => handleApproval('approved')}>
                            Approve
                        </Button>
                        <Button variant="contained" color="error" sx={{ backgroundColor: '#fe0000' }} onClick={() => handleApproval('rejected')}>
                            Reject
                        </Button>
                    </Box></>:""}
                </DialogActions>
            </Dialog>
            {/* ================================================================================ */}
            <Dialog open={CommentDialog} onClose={() => setCommentDialog(false)} fullWidth maxWidth="sm">
                <DialogTitle>
                    Comments
                    <IconButton
                            onClick={() => handleClosePopup(setCommentDialog, "commented_id")}
                            style={{ position: "absolute", right: 8, top: 8 }}
                        >
                            <IconX />
                        </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box display="flex" flexDirection="column" gap={2} p={2}>
                    {CommentsHistory.map((message) => (
                                <Box
                                    key={message.created_at}
                                    display="flex"
                                    justifyContent={userInfo.email === message.commented_by__email ? "flex-end" : "flex-start"}
                                    alignItems="center"
                                    gap={2}
                                    mb={2}
                                >
                                    {userInfo.email !== message.commented_by__email && (
                                        <Avatar sx={{ bgcolor: "#795be8", color: "#fff" }}>
                                            {message.commented_by__email.charAt(0).toUpperCase()}
                                        </Avatar>
                                    )}

                                    <Box>
                                        <Box
                                            sx={{
                                                backgroundColor: userInfo.email === message.commented_by__email ? "#e3f2fd" : "#f0f0f0",
                                                color: userInfo.email === message.commented_by__email ? "#0d47a1" : "#000",
                                                borderRadius: 2,
                                                padding: "8px 16px",
                                                maxWidth: "100%",
                                            }}
                                        >
                                            <Typography variant="body1">{message.comment}</Typography>
                                        </Box>
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                color: "#888",
                                                display: "block",
                                                mt: 0.5,
                                            }}
                                        >
                                            {new Date(message.created_at).toLocaleString()}
                                        </Typography>
                                        {userInfo.email !== message.commented_by__email && (
                                            <Typography sx={{ fontSize: "12px", mt: 0.5 }}>From: {message.commented_by__email}</Typography>
                                        )}
                                    </Box>

                                    {userInfo.email === message.commented_by__email && (
                                        <Avatar sx={{ bgcolor: "#795be8", color: "#fff" }}>
                                            {message.commented_by__email.charAt(0).toUpperCase()}
                                        </Avatar>
                                    )}
                                </Box>
                            ))}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ flexDirection: "column", alignItems: "stretch", gap: 2, p: 3 }}>
                    <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 600, ml: 1 }}
                    >
                        Comment
                    </Typography>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <textarea
                            placeholder="Add Your Comment Here......"
                            rows={10}
                            value={commenttext}
                            onChange={handleCommentChange}
                            style={{
                                width: '100%',
                                height: '150px',
                                borderRadius: '12px',
                                padding: '10px',
                                fontSize: '16px',
                                border: '1px solid #ccc',
                                resize: 'vertical',
                                paddingRight: '50px',
                            }}
                        />
                        <Button
                            style={{
                                position: 'absolute',
                                right: '10px',
                                bottom: '10px',
                                height: '30px',
                                borderRadius: '8px',
                                backgroundColor: '#7a5af9',
                                color: '#fff',
                                border: 'none',
                                cursor: 'pointer',
                                padding: '0 10px',
                            }}
                            onClick={() => handlePostComment()}
                        >
                            <IconSend2 />
                        </Button>
                    </div>


                    {/* <Divider /> */}
                    {/* <Box display="flex" justifyContent="end" sx={{ gap: 2 }}>
                        <Button variant="contained" sx={{ backgroundColor: '#6dc308' }}>
                            Send
                        </Button>
                        <Button variant="contained" color="error" sx={{ backgroundColor: '#fe0000' }}>
                            Reject
                        </Button>
                    </Box> */}
                </DialogActions>
            </Dialog>

            {/* ================================================================================ */}

            <Dialog
                open={OpenMasterDialog}
                onClose={(event, reason) => {
                    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                        return;
                    }
                    setOpenMasterDialog(false)
                }}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>
                Master Input Documentation
                    <IconButton
                        onClick={() => {setOpenMasterDialog(false); reset();}}
                        style={{ position: "absolute", right: 8, top: 8 }}
                    >
                        <IconX />
                    </IconButton>
                </DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <TextField
                            margin="dense"
                            label="Enter Release Name. Ex:Release1"
                            type="text"
                            fullWidth
                            {...register('name', {
                                required: 'Name is required',
                                validate: value => {
                                    if (/\s$/.test(value)) {
                                        return 'Space not allowed at the end';  
                                    }
                                    return true;
                                }
                            })}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                        />


                    </DialogContent>
                    <Box sx={{ m: 2 }}>
                        {BackendError && <Alert severity='error'>{BackendError ? BackendError : null}</Alert>}</Box>
                    <DialogActions>
                        <Button color="warning" onClick={() => { reset(); setOpenMasterDialog(false) }} sx={{ backgroundColor: "#fe0000" }}>
                            Cancel
                        </Button>
                        <Button type="submit" color="primary" disabled={Loader}>
                            {Loader ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                'Save'
                            )}
                        </Button>
                    </DialogActions>
                </form>

            </Dialog>
            </PageContainer>
        </>
    )
}

export default ManagerView
