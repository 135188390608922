import React, { useEffect, useState } from "react";
import { Alert, Button, LinearProgress, Card, CardContent, TextField, Typography, Box, Select, MenuItem, IconButton, Table, TableBody, TableCell, TableRow, TableContainer, TableHead, Paper, FormControl, FormHelperText, styled, DialogContent, Dialog, DialogActions, CircularProgress } from "@mui/material";
import { IconArrowRight, IconCircleCheck, IconFileDiff, IconRosetteDiscountCheck, IconTrash, IconUserPlus, IconX } from "@tabler/icons-react";
import { Container, Delete, FileEdit, Users } from "lucide-react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../MainPages/Breadcrumb";

const AddStakeholder = () => {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, trigger, setValue, clearErrors } = useForm();
    const [stackHolders, setStackHolders] = useState([]);
    const [backenderror, setbackenderror] = useState('')
    const [updatesuccess, setupdatesuccess] = useState(false)
    const [PopupMessage, setPopupMessage] = useState('')
    const [loader,setloader] = useState(false)
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    const projectId = params.get("id");
    const isEdit = params.get("is_edit");
    const getUser = async () => {
        try {
            const response = await axios.get(`/apiV1/projects/${projectId}/`);
            console.log(response.data, 'stakeholdersssssss');

            if (response.status === 200) {
                const managerMail = response.data.manager_email;
                const filteredMembers = response.data.members.filter(
                    (member) => member.email !== managerMail
                );
                setStackHolders(filteredMembers);

            }
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        if (isEdit) {
            getUser()
        }
    }, [isEdit])

    const handleRemoveStackHolder = (id) => {
        clearErrors("")
        setbackenderror('')
        const updatedHolders = stackHolders.filter(holder => holder.id !== id);
        const reassignedHolders = updatedHolders.map((holder, index) => ({ ...holder, id: index }));
        setStackHolders(reassignedHolders);
    };

    const handleAddStackHolder = () => {
        setStackHolders([...stackHolders, { id: stackHolders.length, email: '', designation: '', fullname: '' }]);
    };

    const CreateStacholder = async (data) => {
        const stakeholders = data.stackHolders.map((holder) => ({
            email: holder.email,
            designation_name: holder.designation,
            fullname: holder.fullname
        }));

        const rawdata = stakeholders;
        if (isEdit) {
            setloader(true)
            try {
                const editdata ={stakeholders}
                const response = await axios.patch(`apiV1/edit-project/${projectId}/edit_stakeholder/`, editdata);
                if (response.status === 200) {
                    setPopupMessage('Stake Holders Updated Succesfully ')
                    setupdatesuccess(true)
                    setloader(false)
                    // navigate('/Dashboard');
                }
            } catch (error) {
                console.error('Error generating project:', error.response ? error.response.data : error.message);
                setbackenderror(error.response?.data?.message);
                setloader(false)
            }
        } else {
            try {
                setloader(true)
                const response = await axios.post(`apiV1/projects/${projectId}/add_stakeholder/`, rawdata);
                if (response.status === 200) {
                    // navigate('/Dashboard');
                    setPopupMessage('Stake Holders Added Successfully')
                    setupdatesuccess(true)
                    setloader(false)
                }
            } catch (error) {
                console.error('Error generating project:', error.response ? error.response.data : error.message);
                setbackenderror(error.response?.data?.message);
                setloader(false)
            }
        }
    };
    const onSubmit2 = async (data) => {
        const updatedData = { ...data, stackHolders };
        await CreateStacholder(updatedData);
    };
    const BCrumb = [
        {
            to: '/Dashboard',
            title: 'Dashboard',
        },
    ];
    return (
        <>
            <Breadcrumb breadcrumbs={BCrumb} />
            <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: "center",margin:"16px 0px 0px 0px" }} >
                <CardContent sx={{ p: 5, maxWidth: "900px", width: "100%" }}>
                    <Box sx={{ width: "100%", mb: 3 }}>
                        <LinearProgress
                            variant="determinate"
                            value={100}
                            sx={{
                                height: 10,
                                borderRadius: 5,
                                background: "#d9d9d9",
                            }}
                        />
                    </Box>

                    <Box>
                        <Box display="flex" justifyContent="space-between" mb={4}>
                            <Typography variant="h5" display="flex" alignItems="center" gap={2}>
                                <Users size={24} />
                                Add Stake Holders
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={handleAddStackHolder}
                                sx={{ bgcolor: "#7a5af9" }}
                            >
                                + Stake Holder
                            </Button>
                        </Box>
                        <form onSubmit={handleSubmit(onSubmit2)}>
                        <Box sx={{ overflowY: 'scroll', maxHeight: 450,scrollbarWidth:"thin" }}>
                                {stackHolders.length > 0 && (
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 610 }} aria-label="simple table">
                                            <TableHead sx={{ position: 'sticky', top: 0, zIndex: 2, backgroundColor: 'white' }}>
                                                <TableRow>
                                                    <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>Name <span style={{color:"red",fontWeight:800}}>*</span> </TableCell>
                                                    <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>Stake Holders Email <span style={{color:"red",fontWeight:800}}>*</span></TableCell>
                                                    <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>Designation <span style={{color:"red",fontWeight:800}}>*</span></TableCell>
                                                    <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {stackHolders.map((holder, index) => (
                                                    <TableRow key={holder.id}>
                                                        <TableCell component="th" scope="row" sx={{ p: 0 }}>

                                                            <TextField
                                                                fullWidth
                                                                placeholder="Stake Holders Name"
                                                                {...register(`stackHolders[${index}].fullname`, { required: "Name is required" ,
                                                                    pattern: {
                                                                        value: /^[a-zA-Z\s]*$/,
                                                                        message: 'Only alphabets and spaces are allowed',
                                                                      },
                                                                })}
                                                                error={!!errors?.stackHolders?.[index]?.fullname}
                                                                helperText={errors?.stackHolders?.[index]?.fullname?.message}
                                                                value={holder.fullname}
                                                                onChange={(e) => {
                                                                    const updatedHolders = [...stackHolders];
                                                                    updatedHolders[index].fullname = e.target.value;
                                                                    setStackHolders(updatedHolders);
                                                                    clearErrors(`stackHolders[${index}].fullname`)
                                                                }}

                                                            />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">

                                                            <TextField
                                                                fullWidth
                                                                placeholder="Stake Holders Email"
                                                                {...register(`stackHolders[${index}].email`, { required: "Email is required" ,
                                                                    pattern: {
                                                                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                                                        message: "Enter a valid email",
                                                                    },
                                                                })}
                                                                error={!!errors?.stackHolders?.[index]?.email}
                                                                helperText={errors?.stackHolders?.[index]?.email?.message}
                                                                value={holder.email}
                                                                onChange={(e) => {
                                                                    const updatedHolders = [...stackHolders];
                                                                    updatedHolders[index].email = e.target.value;
                                                                    setStackHolders(updatedHolders);
                                                                    clearErrors(`stackHolders[${index}].email`)
                                                                }}
                                                                onInput={(e) => {e.target.value = e.target.value.toLowerCase()}}
                                                            />

                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <TextField
                                                                fullWidth
                                                                placeholder="Stake Holders designation"
                                                                {...register(`stackHolders[${index}].designation`, { required: "Designation is required",pattern: {
                                                                    value: /^[a-zA-Z\s]*$/,
                                                                    message: 'Only alphabets and spaces are allowed',
                                                                  }, })}
                                                                error={!!errors?.stackHolders?.[index]?.designation}
                                                                helperText={errors?.stackHolders?.[index]?.designation?.message}
                                                                value={holder.designation}
                                                                onChange={(e) => {
                                                                    const updatedHolders = [...stackHolders];
                                                                    updatedHolders[index].designation = e.target.value;
                                                                    setStackHolders(updatedHolders);
                                                                    clearErrors(`stackHolders[${index}].designation`)
                                                                }}

                                                            />
                                                            {/* <FormControl fullWidth className="departmentDropdown mt-2" error={!!errors?.stackHolders?.[index]?.designation}>
                                                                <Select
                                                                    {...register(`stackHolders[${index}].designation`, { required: "Designation is required" })}
                                                                    labelId="department-label"
                                                                    displayEmpty
                                                                    sx={{ borderRadius: '8px', height: '3rem' }}
                                                                    defaultValue={holder.designation} // To ensure the initial value is set
                                                                    onChange={(e) => {
                                                                        const updatedHolders = [...stackHolders];
                                                                        updatedHolders[index].designation = e.target.value;
                                                                        setStackHolders(updatedHolders);
                                                                        clearErrors(`stackHolders[${index}].designation`);
                                                                    }}
                                                                >
                                                                    <MenuItem value="" disabled>
                                                                        Designation
                                                                    </MenuItem>
                                                                    {designationsdata.map((item) => (
                                                                        <MenuItem key={item.id} value={item.id}>
                                                                            {item.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                <FormHelperText>
                                                                    {errors?.stackHolders?.[index]?.designation?.message}
                                                                </FormHelperText>
                                                            </FormControl> */}

                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <IconButton onClick={() => handleRemoveStackHolder(holder.id)} color="error">
                                                                <IconTrash />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </Box>
                            <Box sx={{ mb: 2 }}>

                                {backenderror && <Alert severity="error">{backenderror ? backenderror : null}</Alert>}
                            </Box>
                            {stackHolders.length > 0 && (
                                <Box sx={{ alignItems: "center" }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        size="small"
                                        sx={{ gap: 1 }}
                                        fullWidth
                                    >
                                        {loader ? (
                                        <CircularProgress size={20} color="inherit" />
                                        ) : (
                                        isEdit ? "Update" : "Create"
                                        )}

                                    </Button>
                                </Box>
                            )}
                        </form>
                    </Box>
                </CardContent>
            </Card>
            <Dialog
                open={updatesuccess}
                onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                        setupdatesuccess(false);
                    }
                }}
                aria-labelledby="success-dialog-title"
                aria-describedby="success-dialog-description"
                maxWidth="sm"
                fullWidth
            >
                <DialogContent>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            padding: "16px",
                            gap: 2,
                        }}
                    >
                        <IconCircleCheck size={80} color="#7a5afc" />
                        <Box>
                            <Typography
                                variant="h5"
                                sx={{
                                    fontWeight: "600",
                                    fontSize: "2rem",
                                    color: "#7a5afc",
                                    marginBottom: "8px",
                                }}
                            >
                                Success!
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    fontWeight: "500",
                                    fontSize: "1rem",
                                    color: "textSecondary",
                                }}
                            >
                                {PopupMessage}
                            </Typography>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        sx={{
                            backgroundColor: "#7a5afc",
                            color: "white",
                            "&:hover": {
                                backgroundColor: "#5d1ac0",
                            },
                            textTransform: "none",
                        }}
                        onClick={() => {
                            setupdatesuccess(false);
                            navigate(`/Dashboard`);
                        }}
                    >
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
};

export default AddStakeholder;
