import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Divider, IconButton, Box, Tooltip, Typography } from '@mui/material';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import logo from '../../Images/pss.png';
import { IconEdit, IconFileInvoice, IconGitPullRequest, IconHeadset, IconHome, IconMenu, IconMenu2, IconMessages, IconPasswordUser, IconSettings, IconSteam, IconUserScan, IconUsersGroup, IconX } from '@tabler/icons-react';
import { useMediaQuery } from '@mui/material';
import userprofile from '../../Images/user-1.jpg';

const Sidebar = () => {
    const location = useLocation();
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const userInfo = JSON.parse(sessionStorage.getItem('user-info'));
 const navigate = useNavigate()
    const allMenuItems = [
        { text: 'My Projects', icon: <IconHome />, paths: ['/Dashboard', '/managerinput', '/newproject', '/projectview', '/masterScopeview','/addstakeholder'], roles: ['is_admin', 'is_manager', 'is_stakeholder'] },
        // { text: 'Designation', icon: <IconUserScan />, paths: ['/desination'], roles: ['is_admin'] },
        // { text: 'Stakeholders', icon: <IconUsersGroup />, paths: ['/stakeholders'], roles: ['is_admin'] },
        { text: 'Requests', icon: <IconGitPullRequest />, paths: ['/requests'], roles: ['is_admin','is_manager','is_stakeholder'] },
        { text: 'Documents', icon: <IconFileInvoice />, paths: ['/documents','/generatedfiles','/generateddocs'], roles: ['is_admin','is_manager','is_stakeholder'] },
        // { text: 'Chat', icon: <IconMessages />, paths: ['/chat'], roles: ['is_admin'] },
        // { text: 'Settings', icon: <IconSettings />, paths: ['/settings'], roles: ['is_admin'] },
        // { text: 'Teams', icon: <IconSteam />, paths: ['/teams'], roles: ['is_admin'] },
        { text: 'Help & Support', icon: <IconHeadset />, paths: ['/help-support'], roles: ['is_admin'] },
    ];

    const menuItems = allMenuItems.filter((item) =>
        item.roles.some((role) => userInfo[role])
    );

    const handleToggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const isMobile = useMediaQuery('(max-width: 1200px)');
    useEffect(() => {
        if (isMobile) {
            setIsSidebarOpen(false);
        } else {
            setIsSidebarOpen(true);
        }
    }, [isMobile]);

    return (
        <div
            style={{
                width: isSidebarOpen
                    ? (isMobile ? '300px' : '300px')
                    : (isMobile ? '90px' : '90px'),
                background: 'linear-gradient(to bottom, #072a69, #082c7c, #152d8e, #272c9f, #3b29af, #4526b6, #4f22bc, #591cc2, #591cc2, #591cc2, #591cc2, #591cc2)',
                height: '100vh',
                padding: isSidebarOpen ? '20px' : '10px',
                border: '15px solid #fff',
                borderRadius: '28px',
                overflow: 'hidden',
                transition: 'all 0.3s ease-in-out',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2, }}>
                {isSidebarOpen && (
                    <img
                        src={logo}
                        alt="Logo"
                        style={{
                            width: '80%',
                            transition: 'opacity 0.3s',
                            opacity: isSidebarOpen ? 1 : 0,
                        }}
                    />
                )}
                <IconButton onClick={handleToggleSidebar} sx={{ color: "#fff" }}>
                    {isSidebarOpen ? <IconMenu2 /> : <IconMenu2 />}
                </IconButton>
            </Box>
            {/* {isSidebarOpen && ( <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center', 
                    mb: 2,
                    width: 100,
                    height: 100,
                    position: 'relative',
                    left:"22%"
                }}
            >
               
                    <img
                        src={userprofile}
                        alt="Logo"
                        style={{
                            width: '100%',
                            transition: 'opacity 0.3s',
                            opacity: isSidebarOpen ? 1 : 0,
                            borderRadius: "50%",
                            margin: "auto",
                            display: "block", 
                        }}
                    />
                    <Typography>Manager</Typography>
                    <Typography>Ajay</Typography>
                
            </Box>)} */}

            <Divider />
            <div style={{ flex: 1,overflowY: 'auto',scrollbarWidth: 'none', msOverflowStyle: 'none'}}>
                <List>
                    {menuItems.map((item, index) => {
                        const isActive = item.paths.some(path => location.pathname.includes(path));
                        return (
                            <ListItem  button key={index} component={Link} to={item.paths[0]}
                                sx={{
                                    backgroundColor: isActive ? '#fff' : 'transparent',
                                    borderRadius: '5px',
                                    margin: '5px 0',
                                    '&:hover': {
                                        backgroundColor: isActive ? '#fff' : '#8c93e5',
                                    },
                                    justifyContent: isSidebarOpen ? 'flex-start' : 'center',
                                }}
                            >
                                <Tooltip title={item.text} placement="right" disableHoverListener={isSidebarOpen}  arrow>
                                    <ListItemIcon  sx={{color: isActive ? '#7a5af9' : '#fff', minWidth: isSidebarOpen ? '35px' : 'auto'}}>
                                        {item.icon}
                                    </ListItemIcon>
                                </Tooltip>
                                {isSidebarOpen && (
                                    <ListItemText sx={{ color: isActive ? '#7a5af9' : '#fff' }}>
                                        {item.text}
                                    </ListItemText>
                                )}
                            </ListItem>
                        );
                    })}
                </List>
            </div>  
            <Box sx={{ padding: isSidebarOpen ? "10px":"0px",background: "#fff",borderRadius: "10px",display: "flex",alignItems: "center",gap: "10px",cursor:"pointer"}} onClick={()=>navigate('/myprofile')}>
                <Box>
                    <img
                        src={userprofile}
                        alt="User Profile"
                        style={{
                            width: "40px",
                            height: "40px",
                            transition: "opacity 0.3s",
                            borderRadius: "50%",
                            margin: "auto",
                            display: "block",
                        }}
                    />
                </Box>
                {isSidebarOpen && (
                    <Box sx={{ textAlign: "left",color: "#fff"}} >
                        <Typography variant="body1" fontWeight="bold" color='#4227b4'>{userInfo.fullname}</Typography>
                        <Tooltip title={userInfo.email} arrow>
                        <Typography variant="body2" fontWeight="bold" color='#4227b4'  sx={{overflow: "hidden",textOverflow: "ellipsis",whiteSpace: "nowrap",maxWidth: "150px",cursor: "pointer"}}>{userInfo.email.length > 18 ? `${userInfo.email.slice(0, 18)}...` : userInfo.email}</Typography>
                        </Tooltip>
                    </Box>
                )}
            </Box>

            <style>
                {`
                div::-webkit-scrollbar {
                    display: none;
                }
            `}
            </style>
        </div>
    );
};

export default Sidebar;
