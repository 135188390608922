import { ErrorOutline, CheckCircle } from '@mui/icons-material';
import { Button, IconButton, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { Box, Grid } from '@mui/system';
import { IconEdit, IconMail, IconPlus, IconTrash, IconEye, IconEyeOff, IconLock, IconX, IconUser, IconPhone } from '@tabler/icons-react';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { useForm } from 'react-hook-form';

const Stakeholder = () => {
    const { register, handleSubmit, formState: { errors }, trigger, getValues, clearErrors, setValue, reset } = useForm();
    const [isSubmitting, setisSubmitting] = useState(false)
    const [open, setOpen] = useState(false)
    const [OpenError, setOpenError] = useState(false)
    const [data, setdata] = useState(false)
    const [editData, setEditdata] = useState('')
    const [showPassword, setShowPassword] = useState(false);
    const [showconfirmPassword, setshowconfirmPassword] = useState(false)
    const [designationsdata, setdesignationsdata] = useState([])
    const [message, setMessage] = useState("");
    const [MessageType, setMessageType] = useState("");
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedDesignation, setselectedDesignation] = useState()
    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };
    const toggleconfirmPasswordVisibility = () => {
        setshowconfirmPassword((prev) => !prev);
    };
    const columns = [
        {
            name: <Typography variant="subtitle2" fontWeight={600}>Full Name</Typography>,
            selector: (row) => row.meta, cell: row => row.fullname
        },
        {
            name: <Typography variant="subtitle2" fontWeight={600}>Email</Typography>,
            selector: (row) => row.meta, cell: row => row.email
        },
        // {
        //     name: <Typography variant="subtitle2" fontWeight={600}>Designation</Typography>,
        //     selector: (row) => row.meta, cell: row => row.designation
        // },
        // {
        //     name: <Typography variant="subtitle2" fontWeight={600}>Actions</Typography>,
        //     selector: (row) => row.meta,
        //     cell: row => (
        //         <>
        //             <div
        //                 className="DarkBlue4_Color font_weight_300 font_14 cursor-pointer"
        //                 role="button"
        //                 onClick={() => setOpen(true)}
        //                 style={{ marginRight: '10px' }}
        //             >
        //                 <IconEdit color={"#182286"} onClick={() => { setEditdata(row); onloadeditData(row) }} />
        //             </div>
        //             <div
        //                 className="DarkBlue4_Color font_weight_300 font_14 cursor-pointer"
        //                 role="button"
        //                 onClick={() => setOpen(true)}
        //             >
        //                 <IconTrash color={"red"} />
        //             </div>
        //         </>
        //     ),
        // }

    ];
    const getUser = async (page = currentPage, rowsperpage = perPage) => {
        try {
            const response = await axios.get(`apiV1/stakeholders/?limit=${rowsperpage}&offset=${(page-1) * rowsperpage}`);
            setdata(response.data)
            setTotalRows(response.data.count)
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
            setisSubmitting(false)

        }
    };
    const onSubmit = async (data) => {
        console.log(data);

        if (editData) {
            setisSubmitting(true)
            try {
                const req = {
                    fullname: data.Fullname,
                    mobile: data.phone,
                    email: data.email,
                    designation: data.designation,
                };
                const response = await axios.patch(`/apiV1/edit-stake-user/${editData.id}/`, req);
                console.log(response, 'response');
                if (response.status === 200) {
                    setMessageType("Success")
                    setMessage(response.data.message)
                    setOpen(false)
                    setOpenError(true)
                    setisSubmitting(false)
                    getUser()
                }
            } catch (error) {
                console.error("Error generating project:", error.response ? error.response.data : error.message);
                setisSubmitting(false)
                setOpen(false)
                setMessageType("Error")
                setOpenError(true)
                setMessage(error.response.data.message)
            }
        }
        else {
            setisSubmitting(true)
            try {

                const req = {
                    fullname: data.Fullname,
                    mobile: data.phone,
                    email: data.email,
                    designation: data.designation,
                    password: data.password,
                    confirm_password: data.confirmPassword,
                };
                const response = await axios.post("apiV1/create-stakeholder/", req);
                if (response.status === 201) {
                    setMessage(response.data.message)
                    setOpen(false)
                    setisSubmitting(false)
                    setMessageType("Success")
                    setOpenError(true)
                    getUser()
                }
            } catch (error) {
                console.log(error.response.data.detail);
                console.error("Error generating project:", error.response ? error.response.data : error.message);
                setisSubmitting(false)
                setOpen(false)
                setOpenError(true)
                setMessageType("Error")
                setMessage(error.response.data.message)
            }
        }


    };
    useEffect(() => {
        getUser()
    }, [])
    const handleClose = () => {
        setOpen(false);
        clearErrors();
        setValue('');
        reset();
    }
    const getdesignations = async (page) => {
        try {
            const response = await axios.get(`apiV1/designations/`);
            if (response.status === 200) {
                setdesignationsdata(response.data)
            }
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
        }
    };
    useEffect(() => {
        getdesignations()
    }, [])
    const onloadeditData = (data) => {
        console.log(data, 'data');
        setselectedDesignation(data.id)
        setValue('Fullname', data.fullname)
        setValue('email', data.email)
        setValue('phone', data.mobile)
        setValue('designation',data.designation)
    }
   
    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const handlePageChange = page => {
        getUser(page, perPage, searchQuery);
        setCurrentPage(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        getUser(page, newPerPage, searchQuery);
        setPerPage(newPerPage);
    };
    return (
        <>
            <Card sx={{ display: 'flex', flexDirection: 'column', p: 5, margin: "0px 0px 0px 9px", position: 'relative' }}>
                <CardContent sx={{ alignContent: 'center' }}>
                    <Box sx={{ position: 'absolute', top: 6, right: 10, gap: 1 }}>
                        <Button sx={{ mb: 2 }} onClick={() => { setOpen(true); reset(); setEditdata(''); setselectedDesignation('') }} variant="contained"><IconPlus />Stake holder</Button>
                    </Box>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        paginationServer
                        fixedHeaderScrollHeight='600px'
                        fixedHeader
                        persistTableHead
                        paginationTotalRows={totalRows}
                        paginationComponentOptions={paginationComponentOptions}
                        paginationPerPage={perPage}
                        paginationDefaultPage={currentPage}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                    />
                </CardContent>
            </Card>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                <DialogTitle>
                    Stake Holder
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {setOpen(false);reset()}}
                        aria-label="close"
                        sx={{ position: 'absolute', right: 20, top: 8, }}
                    >
                        <IconX />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{ mb: 1 }}>
                            <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: 500, mb: 1, display: "block" }}
                            >
                                Full Name
                            </Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter Fullname"
                                {...register("Fullname", { required: "Fullname  is required" })}
                                error={!!errors.Fullname}
                                helperText={errors.Fullname?.message}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconUser size={22} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box sx={{ mb: 1 }}>
                            <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: 500, mb: 1, display: "block" }}
                            >
                                Mobile
                            </Typography>
                            <TextField
                                fullWidth
                                placeholder="Phone"
                                variant="outlined"
                                margin="normal"
                                {...register("phone", {
                                    required: "This field is required",
                                    pattern: {
                                        value: /^[1-9]{1}[0-9]{9,11}$/,
                                        message:
                                            "Please enter a valid mobile number",
                                    },
                                    minLength: {
                                        value: 10,
                                        message: "Please enter min 10 numbers ",
                                    },
                                    maxLength: {
                                        value: 12,
                                        message: "Please enter max 12 numbers ",
                                    },
                                })}
                                onKeyUp={() => trigger('phone')}
                                error={!!errors.phone}
                                helperText={errors.phone?.message}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconPhone size={22} />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    marginTop: "0px",
                                }}

                            />
                        </Box>
                        <Box sx={{ mb: 1 }}>
                            <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: 500, mb: 1, display: "block" }}
                            >
                                Email
                            </Typography>
                            <TextField
                                fullWidth
                                placeholder="Email"
                                type="email"
                                margin="normal"
                                {...register("email", {
                                    required: "This field is required",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]{2,}\.+[A-Z]{2,5}$/i,
                                        message: "Please enter a valid email address.",
                                    },
                                })}
                                onKeyUp={() => trigger('email')}
                                error={!!errors.email}
                                helperText={errors.email?.message}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconMail size={22} />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    marginTop: "0px",
                                }}
                            />
                        </Box>
                        {/* <Box sx={{ mb: 1 }}>
                            <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: 500, mb: 1, display: "block" }}
                            >
                                Designation
                            </Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter Designation"
                                {...register("designation", { required: "Designation  is required" })}
                                error={!!errors.designation}
                                helperText={errors.designation?.message}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconUser size={22} />
                                        </InputAdornment>
                                    ),
                                }}
                            /> */}
                            {/* <FormControl fullWidth>
                                <InputLabel id="department-label">Select Designation</InputLabel>
                                <Select
                                    {...register('designation')}
                                    labelId="department-label"
                                    label="Select Designation"
                                    sx={{ borderRadius: '8px', height: '3rem' }}
                                    value={selectedDesignation}
                                    onChange={(e) => { setselectedDesignation(e.target.value); clearErrors("designation") }}
                                >
                                    <MenuItem value="" disabled>
                                        Designation
                                    </MenuItem>
                                    {designationsdata.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl> */}
                        {/* </Box> */}
                        {!editData ? <><Box>
                            <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: 500, mb: 1, display: "block" }}
                            >
                                Password
                            </Typography>
                            <TextField
                                fullWidth
                                placeholder="Password"
                                variant="outlined"
                                margin="normal"
                                type={showPassword ? 'text' : 'password'}
                                {...register("password", {
                                    required: "This field is required",
                                    pattern: {
                                        value: /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}$/,
                                        message: "Password must be 8-16 characters, with at least one digit, one lowercase, and one uppercase letter.",
                                    },
                                })}
                                sx={{
                                    marginTop: "0px",
                                }}
                                error={!!errors.password}
                                helperText={errors.password?.message}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconLock size={22} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={togglePasswordVisibility} edge="end">
                                                {showPassword ? <IconEye size={22} /> : <IconEyeOff size={22} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onKeyUp={() => {
                                    trigger("password");
                                }}
                                inputProps={{
                                    autoComplete: 'password',
                                }}
                                onCopy={(e) => e.preventDefault()}
                                onPaste={(e) => e.preventDefault()}
                            />
                        </Box>
                            <Box>
                                <Typography
                                    variant="subtitle1"
                                    sx={{ fontWeight: 500, mb: 1, display: "block" }}
                                >
                                    Confirm Password
                                </Typography>
                                <TextField fullWidth
                                    placeholder="Confirm Password"
                                    variant="outlined"
                                    type={showconfirmPassword ? 'text' : 'password'}
                                    margin="normal"
                                    {...register('confirmPassword', {
                                        required: 'Confirm Password is required',
                                        validate: (value) =>
                                            value === getValues('password') || 'Passwords do not match',
                                    })}
                                    onKeyUp={() => trigger("confirmPassword")}
                                    error={!!errors.confirmPassword}
                                    helperText={errors.confirmPassword?.message}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconLock size={22} />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={toggleconfirmPasswordVisibility} edge="end">
                                                    {showconfirmPassword ? <IconEye size={22} /> : <IconEyeOff size={22} />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onCopy={(e) => e.preventDefault()}
                                    onPaste={(e) => e.preventDefault()}
                                    sx={{
                                        marginTop: "0px",
                                    }}
                                />
                            </Box></> : null}


                        <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
                            <Button variant="contained" color="warning" onClick={() => { handleClose() }} sx={{ marginRight: '10px' ,backgroundColor:'#fe0000'}}>  Cancel </Button>
                            <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>{isSubmitting ? <CircularProgress disabled style={{ height: '30px', width: '30px', textAlign: "center" }} /> : "Submit"}</Button>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
            <Dialog
                open={OpenError}
                aria-labelledby="success-dialog-title"
                aria-describedby="success-dialog-description"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="success-dialog-title" className='bg-my-primary p-3 font_weight_700'>
                    {MessageType}
                </DialogTitle>
                <DialogContent dividers style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                    <Box className="text-danger font_54">
                        {MessageType === "Success" ? (<CheckCircle fontSize="large" color="success" />) : (<ErrorOutline fontSize="large" color="error" />)}
                    </Box>
                    <Typography variant="h6" className="red_dark_text font_weight_700 mt-2">
                        {message}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Box display="flex" justifyContent="center" width="100%" mb={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setOpenError(false);
                            }}
                        >
                            Okay
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default Stakeholder
