import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Typography,
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  ButtonBase,
  CircularProgress,
  Dialog,
  Badge,
  Snackbar,
  Alert
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IconBell, IconChevronLeft, IconLockCode, IconLogout, IconMessage, IconRefresh, IconUserCircle } from '@tabler/icons-react';
import axios from 'axios';

import breadcrumbImg from '../../Images/user-1.jpg';
import notificationSound from '../../Images/sound.mp3'; 
const Breadcrumb = ({breadcrumbs }) => {
  const navigate = useNavigate();

  const [anchorElMessage, setAnchorElMessage] = useState(null);
  const [anchorElAvatar, setAnchorElAvatar] = useState(null);
  const [openMessage, setOpenMessage] = useState(false);
  const [openAvatar, setOpenAvatar] = useState(false);
  const [messages, setMessages] = useState([]);
  const reactcolors = ['#fed466', '#6bc8f3', '#8cd6bb', '#fa9962', '#e875ba'];
  const [count,setcount]= useState(0)
  const previousCount = useRef(0);
  const [showPopup, setShowPopup] = useState(false);
  const audio = useRef(new Audio(notificationSound));
  const handleOpenMessage = (event) => {
    setAnchorElMessage(event.currentTarget);
    setOpenMessage(true);
    setAnchorElAvatar(null);
    setOpenAvatar(false);
  };
  const handleOpenAvatar = (event) => {
    setAnchorElAvatar(event.currentTarget);
    setOpenAvatar(true);
    setAnchorElMessage(null);
    setOpenMessage(false);
  };

  const handleCloseAvatar = () => {
    setAnchorElAvatar(null);
    setOpenAvatar(false);
  };

  const getNotifications = async () => {
    try {
      const response = await axios.get('/apiV1/notifications/');
      const currentCount = response.data?.length || 0;
      setMessages(response.data);
      setcount(currentCount);
      if (previousCount.current !== 0 && currentCount > previousCount.current) {
        setShowPopup(true);
        audio.current.play();
      }
      previousCount.current = currentCount;
    } catch (error) {
      console.error(
        'Error fetching notifications:',
        error.response ? error.response.data : error.message
      );
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getNotifications();
    }, 3000);
    return () => clearInterval(interval);
  }, [openMessage]);

  useEffect(() => {
    getNotifications();
  }, []);

  const onNotificationClick = async (index) => {
    try {
      const response = await axios.get(`/apiV1/notifications/?index=${index}`);
      if (response.status === 200) {
        const finalUrl = response.data.url.startsWith('http') ? response.data.url : `/${response.data.url.replace(/^\/+/, '')}`;
        window.location.href = finalUrl;
        getNotifications();
        setOpenMessage(false)
      }
    } catch (error) {
      console.error(
        'Error fetching notification details:',
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleLogout = () => {
    sessionStorage.removeItem('user-info');
    sessionStorage.removeItem('AdminToken');
    navigate('/');
  };
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true); 
    setTimeout(() => {
      setLoading(false); 
      getNotifications(); 
    }, 2000); 
  };
  return (
    <>
    <Grid
      container
      sx={{
        backgroundColor: '#fff',
        boxShadow:
          'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
        borderRadius: (theme) => theme.shape.borderRadius / 4,
        p: '6px',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {/* Left Section (Breadcrumb or Title) */}
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        lg={6}
        display="flex"
        alignItems="center"
        sx={{ justifyContent: { xs: 'center', sm: 'flex-start' } }}
      >
        <Typography
          variant="h6"
          sx={{
            color: '#5a1bc2',
            fontWeight: 'bold',
            textAlign: { xs: 'center', sm: 'left' },
            mx:2
          }}
        >
          {breadcrumbs?.map((crumb, index) => (
            <span key={index}>
              {crumb.to ? (
                <Button
                  variant="outlined"
                  onClick={() => navigate(crumb.to)}
                  sx={{ color: '#5a1bc2', textTransform: 'none', fontWeight: 'bold',background:"none" ,gap:1,padding:"5px 15px 5px 10px"}}
                >
                 <IconChevronLeft/> {crumb.title}
                </Button>
              ) : (
                <span>{crumb.title}</span>
              )}
              {index < breadcrumbs.length - 1 && ' < '}
            </span>
          ))}
        </Typography>
      </Grid>

      {/* Right Section (Icons and Avatar) */}
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        lg={6}
        display="flex"
        alignItems="center"
        sx={{
          justifyContent: { xs: 'center', sm: 'flex-end' },
          mt: { xs: 2, sm: 0 },
        }}
      >
         <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
            gap: 2,
            flexWrap: { xs: 'wrap', sm: 'nowrap' },
          }}
        >
          <Badge badgeContent={count} overlap="circular" sx={{
            '.MuiBadge-badge': {
              fontSize: '0.75rem',
              minWidth: '20px',
              height: '20px',
              backgroundColor: '#5828b0',
              color: '#fff',
            },
          }}
          >
            <IconMessage
              size={42}
              onClick={handleOpenMessage}
              color="#5a1bc2"
              style={{ cursor: 'pointer' }}
            />
          </Badge>
          <Box sx={{ position: 'relative', ml: 2 }}>
            <img
              src={breadcrumbImg}
              alt="Avatar"
              onClick={handleOpenAvatar}
              style={{ maxWidth: '50px', borderRadius: '50%', cursor: 'pointer' }}
            />
          </Box>
        </Box>
      </Grid>

      {/* Message Menu */}
      <Dialog
      open={openMessage} onClose={() => setOpenMessage(false)}
      maxWidth="sm"
      fullWidth
      hideBackdrop
      PaperProps={{
        sx: {
          position: 'absolute',
          top: "10%",
          right: "1%",
          margin: 0,
          width: '350px', 
          overflow: 'hidden',
          padding: 0,
          boxShadow: 3,
        },
      }}
    >
      <Box sx={{ width: '100%', maxWidth: 400, boxShadow: 3, p: 0 }}>
        {/* Header */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px 16px',
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#7a6ac3' }}>
            Notifications
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Button
              sx={{
                backgroundColor: 'transparent',
                color: '#7a5bf6',
                fontWeight: 600,
                textTransform: 'none',
                boxShadow: 'none',
                border: 'none',
                padding: 0,
                '&:hover': { backgroundColor: 'transparent', color: '#6747cc' },
              }}
              onClick={handleClick}
            >
              <IconRefresh />
            </Button>
            <Typography variant="subtitle2" sx={{ fontSize: '14px', color: '#5a1bc2' }}>
              <IconBell />
            </Typography>
          </Box>
        </Box>

        <Divider />

        {/* Notifications List */}
        <Box
          sx={{
            maxHeight: 300,
            overflowY: 'auto',
            '&::-webkit-scrollbar': { width: '3px' },
            '&::-webkit-scrollbar-thumb': { backgroundColor: '#5a1bc2', borderRadius: '10px' },
            '&::-webkit-scrollbar-track': { backgroundColor: '#f0f0f0' },
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 300,
              }}
            >
              <CircularProgress size={35} />
            </Box>
          ) : messages.length > 0 ? (
            messages.map((notification, index) => {
              const firstLetter = notification.created_by.charAt(0).toUpperCase();
              return (
                <ButtonBase
                  key={index}
                  onClick={() => onNotificationClick(index)}
                  sx={{
                    display: 'flex',
                    width: '100%',
                    textAlign: 'left',
                    padding: '10px 16px',
                    borderBottom: '1px solid #e0e0e0',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      maxWidth: 60,
                      maxHeight: 60,
                      borderRadius: '50%',
                      backgroundColor: reactcolors[index % reactcolors.length],
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: '18px',
                      textAlign: 'center',
                      marginRight: 2,
                      padding: 2,
                    }}
                  >
                    {firstLetter}
                  </Box>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      {notification.created_by}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {notification.description}
                    </Typography>
                    <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                      {notification.time_difference} hrs ago
                    </Typography>
                  </Box>
                </ButtonBase>
              );
            })
          ) : (
            <Typography
              variant="body1"
              sx={{ textAlign: 'center', padding: '16px', color: 'text.secondary' }}
            >
              No Notifications Found
            </Typography>
          )}
        </Box>
      </Box>
    </Dialog>

      {/* Avatar Menu */}
      <Menu
        anchorEl={anchorElAvatar}
        open={Boolean(anchorElAvatar)}
        onClose={handleCloseAvatar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ mt: 2 }}
      >
        <MenuItem sx={{p:1}}onClick={()=>{navigate('/myprofile');handleCloseAvatar()}}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <IconUserCircle  color='#5a1bc2'/> My Profile
          </Box>
        </MenuItem>
        <MenuItem sx={{p:1}}onClick={()=>{navigate('/changepassword');handleCloseAvatar()}}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <IconLockCode color='#5a1bc2' /> Change Password
          </Box>
        </MenuItem>
        <MenuItem  sx={{p:1}}onClick={handleLogout}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1,mx:0.5 }}>
          <IconLogout  color='#5a1bc2'/> Logout
          </Box>
        </MenuItem>
      </Menu>
    </Grid>
    <Snackbar
        open={showPopup}
        autoHideDuration={3000} 
        onClose={() => setShowPopup(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
        sx={{ mt: 5 }}
      >
        <Alert
          onClose={() => setShowPopup(false)}
          severity="success" 
          variant="filled" 
          sx={{ width: '100%',top:10 }} 
        >
          You received a new message!
        </Alert>
    </Snackbar>
</>
  );
};

export default Breadcrumb;
