import React, { useState } from 'react'
import { Alert, Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { IconCircleCheck, IconEye, IconEyeOff, IconLock } from '@tabler/icons-react'
import PageContainer from '../MainPages/PageContainer'
import Image from '../../Images/singup.png'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const ChangePassword = () => {
    const { register, handleSubmit, formState: { errors }, getValues, trigger, setValue, reset } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const [showoldPassword, setShowoldPassword] = useState(false);
    const [showconfirmPassword, setshowconfirmPassword] = useState(false)
    const [Error, setError] = useState('')
    const [updatesuccess, setupdatesuccess] = useState(false)
    const [loading, setLoading] = useState(false);
    const userInfo = JSON.parse(sessionStorage.getItem('user-info'));
    const toggleoldPasswordVisibility = () => {
        setShowoldPassword((prev) => !prev);
    };
    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };
    const toggleconfirmPasswordVisibility = () => {
        setshowconfirmPassword((prev) => !prev);
    };
    const navigate= useNavigate()
    const onSubmit = async (data) => {
        const reqData = {
            password: data.password,
            password2: data.confirmPassword,
            old_password: data.oldpassword

        }
        if (data.oldpassword === data.password) {
            setError("The new password must be different from the old password")
        }
        else {
            try {
                const response = await axios.patch(`/apiV1/change-password/${userInfo.id}/`, reqData);
                if (response.status == 200) {
                    setupdatesuccess(true);
                    reset();
                }
            } catch (error) {
                setError(error.response.data.old_password.old_password);
                setupdatesuccess(false);
            }

        }

    }
    return (
        <>
            <PageContainer title='Batool | Change Password'>
                <Card sx={{ display: 'flex', flexDirection: 'column', margin: "0px 0px 0px 9px" }} >
                    <Box >
                        <Typography variant="h6" sx={{ fontWeight: 600, ml: 5, mt: 2 }}>
                            Change Password
                        </Typography>
                    </Box>
                    <CardContent sx={{ p: 5, width: "100%" }}>
                        <Grid container spacing={3} sx={{ justifyContent: 'space-around' }}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <img
                                    src={Image}
                                    alt="Image Description"
                                    style={{ width: "100%", height: "auto", borderRadius: "8px" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={6} sx={{ justifyContent: "center", textAlign: "center" }}>
                                <Box sx={{ justifyContent: "center", textAlign: "center" }}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <TextField
                                            sx={{ maxWidth: 500 }}
                                            fullWidth
                                            label={<>Old Password <span style={{ color: 'red', fontWeight: 800, fontSize: 20 }}>*</span></>}
                                            variant="outlined"
                                            margin="normal"
                                            type={showoldPassword ? 'text' : 'password'}
                                            {...register("oldpassword", {
                                                required: "Old Password is required",
                                                pattern: {
                                                    value: /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}$/,
                                                    message: "Password must be 8-16 characters, with at least one digit, one lowercase, and one uppercase letter.",
                                                },
                                            })}
                                            error={!!errors.oldpassword}
                                            helperText={errors.oldpassword?.message}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <IconLock size={22} />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={toggleoldPasswordVisibility} edge="end">
                                                            {showoldPassword ? <IconEye size={22} /> : <IconEyeOff size={22} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onKeyUp={() => { trigger("oldpassword"); setError('') }}
                                            inputProps={{ autoComplete: 'oldpassword' }}
                                            onCopy={(e) => e.preventDefault()}
                                            onPaste={(e) => e.preventDefault()}
                                        />
                                        <TextField
                                            sx={{ maxWidth: 500 }}
                                            fullWidth
                                            label={<>New Password <span style={{ color: 'red', fontWeight: 800, fontSize: 20 }}>*</span></>}
                                            variant="outlined"
                                            margin="normal"
                                            type={showPassword ? 'text' : 'password'}
                                            {...register("password", {
                                                required: "New Password  is required",
                                                pattern: {
                                                    value: /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}$/,
                                                    message: "Password must be 8-16 characters, with at least one digit, one lowercase, and one uppercase letter.",
                                                },
                                            })}
                                            error={!!errors.password}
                                            helperText={errors.password?.message}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <IconLock size={22} />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={togglePasswordVisibility} edge="end">
                                                            {showPassword ? <IconEye size={22} /> : <IconEyeOff size={22} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onKeyUp={() => { trigger("password"); setError('') }}
                                            inputProps={{ autoComplete: 'password' }}
                                            onCopy={(e) => e.preventDefault()}
                                            onPaste={(e) => e.preventDefault()}
                                        />
                                        <TextField
                                            sx={{ maxWidth: 500 }}
                                            fullWidth
                                            label={<> Confirm Password <span style={{ color: 'red', fontWeight: 800, fontSize: 20 }}>*</span></>}
                                            variant="outlined"
                                            type={showconfirmPassword ? 'text' : 'password'}
                                            margin="normal"
                                            {...register('confirmPassword', {
                                                required: 'Confirm Password is required',
                                                validate: (value) =>
                                                    value === getValues('password') || 'Passwords do not match',
                                            })}
                                            onKeyUp={() => { trigger("confirmPassword"); setError("") }}
                                            error={!!errors.confirmPassword}
                                            helperText={errors.confirmPassword?.message}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <IconLock size={22} />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={toggleconfirmPasswordVisibility} edge="end">
                                                            {showconfirmPassword ? <IconEye size={22} /> : <IconEyeOff size={22} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onCopy={(e) => e.preventDefault()}
                                            onPaste={(e) => e.preventDefault()}
                                        />
                                        <Box sx={{ mb: 2 ,textAlign:"center",justifyContent:"center",display:"flex"}}> {Error && <Alert severity='error'>{Error ? Error : null}</Alert>}</Box>
                                        <Button fullWidth sx={{ maxWidth: 500 }} type='submit'>Change Password</Button>
                                    </form>
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>

                </Card>
                <Dialog
                    open={updatesuccess}
                    onClose={(event, reason) => {
                        if (reason !== "backdropClick") {
                            setupdatesuccess(false);
                        }
                    }}
                    aria-labelledby="success-dialog-title"
                    aria-describedby="success-dialog-description"
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogContent>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                textAlign: "center",
                                padding: "16px",
                                gap: 2,
                            }}
                        >
                            <IconCircleCheck size={80} color="#7a5afc" />
                            <Box>
                                <Typography variant="h5" sx={{ fontWeight: "600", fontSize: "2rem", color: "#7a5afc", marginBottom: "8px" }}>Success!</Typography>
                                <Typography variant="body2" sx={{ fontWeight: "500", fontSize: "1rem", color: "textSecondary" }}>Password Updated Succesfully</Typography>
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" sx={{ backgroundColor: "#7a5afc", color: "white", "&:hover": { backgroundColor: "#5d1ac0" }, textTransform: "none" }} onClick={() => { {setupdatesuccess(false);navigate("/")} }}>Okay</Button>
                    </DialogActions>
                </Dialog>
            </PageContainer>
        </>
    )
}

export default ChangePassword
