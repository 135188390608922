import React, { useEffect, useState } from "react";
import { Alert, Button, LinearProgress, Card, CardContent, TextField, Typography, Box, Select, MenuItem, IconButton, Table, TableBody, TableCell, TableRow, TableContainer, TableHead, Paper, FormControl, FormHelperText, styled, Dialog, DialogContent, Divider, DialogActions, CircularProgress } from "@mui/material";
import { IconArrowRight, IconCircleCheck, IconCircleDashedCheck, IconFileDiff, IconRosetteDiscountCheck, IconTrash, IconUserPlus, IconX } from "@tabler/icons-react";
import { Container, Delete, FileEdit, Users } from "lucide-react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "./Breadcrumb";
import PageContainer from "./PageContainer";

const NewProject = () => {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, trigger, setValue, clearErrors } = useForm();
    const [backenderror, setbackenderror] = useState('')
    const [updatesuccess, setupdatesuccess] = useState(false)
    const [updateMessage, setupdateMessage] = useState('')
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");
    const is_view = params.get("is_edit");
    const [loader, setLoader] = useState(false)
    const [Projectid,setProjectid]= useState("")
    const onSubmit = async (data) => {
        if (is_view) {
            setLoader(true)
            try {
                const req = {
                    name: data.projectName,
                    description: data.Description,
                };
                const response = await axios.patch(`apiV1/projects/${id}/`, req);
                console.log(response, 'responseresponseresponse');

                if (response.status === 200) {
                    setLoader(false)
                    setupdateMessage("Project has been successfully updated")
                    //  navigate(`/addstakeholder?id=${id}`)//
                    setupdatesuccess(true)
                }
            } catch (error) {
                console.error("Error generating project:", error.response ? error.response.data : error.message);
                setbackenderror(error.response.data.message)
                setLoader(false)
            }
        }
        else {
            try {
                setLoader(true)
                const req = {
                    name: data.projectName,
                    manager: data.managerEmail,
                    description: data.Description,
                };
                const response = await axios.post("apiV1/projects/", req);
                console.log(response, 'responseresponseresponse');

                if (response.status === 201) {
                    // setStep((prev) => Math.min(prev + 1, 2));
                    setupdatesuccess(true)
                    setupdateMessage("Project has been successfully Created")
                    setProjectid(response.data.data.id)
                    // navigate(`/addstakeholder?id=${response.data.data.id}`)
                    setbackenderror('')
                    setLoader(false)

                }
            } catch (error) {
                console.error("Error generating project:", error.response ? error.response.data : error.message);
                setbackenderror(error.response.data.message)
                setLoader(false)
            }
        }
    };


    const getUser = async () => {
        try {
            const response = await axios.get(`/apiV1/projects/${id}/`);
            console.log(response.data);
            if (response.status == 200) {
                setValue('Description', response.data.description)
                setValue('projectName', response.data.name)
                setValue('managerEmail', response.data.manager_email)
            }
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
        }
    };
    useEffect(() => {
        if (id) {
            getUser()
        }
    }, [id])


    const blue = "#7a5afa";
    const Textarea = styled('textarea')(
        ({ theme }) => `
        box-sizing: border-box;
        width: 100%;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 8px 12px;
        border-radius: 8px;
      
        &:hover {
          border-color: ${blue};
        }
      
        &:focus {
          border-color: ${blue};
          box-shadow: 0 0 0 1px ${theme.palette.mode === 'dark' ? blue : blue};
        }
      
        /* Firefox */
        &:focus-visible {
          outline: 0;
        }
      
        /* For multiline textarea */
        min-height: 50px;
        resize: vertical;
        `,
    );
    const BCrumb = [
        {
          to: '/Dashboard',
          title: 'DashBoard',
        }
      ];
    return (
        <>
        <PageContainer title="Create Project">
         <Breadcrumb breadcrumbs={BCrumb} />
            <Card
                sx={{ display: 'flex', flexDirection: 'column', margin: "16px 0px 0px 0px", alignItems: "center" }}
            >
                <CardContent sx={{ p: 5, maxWidth: "700px", width: "100%" }}>
                    <Box sx={{ width: "100%", mb: 3 }}>
                        <LinearProgress
                            variant="determinate"
                            value={50}
                            sx={{
                                height: 10,
                                borderRadius: 5,
                                background: "#d9d9d9",
                                "& .MuiLinearProgress-bar": {
                                    backgroundColor:"#7a5afa",
                                },
                            }}
                        />
                    </Box>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 4 }}>
                                <Typography variant="h4">Create Your Project</Typography>
                                <FileEdit size={24} color="#7C3AED" />
                            </Box>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Box sx={{ mb: 1 }}>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ fontWeight: 500, mb: 1, display: "block" }}
                                    >
                                        Project Name <span style={{color:"red",fontWeight:800}}>*</span>
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        placeholder="Enter Project Name"
                                        {...register("projectName", {
                                            required: "Project Name is required",
                                            maxLength: {
                                                value: 50,
                                                message: "Project Name must be less than 50 characters ",
                                            },
                                            pattern: {
                                                value: /^[a-zA-Z0-9\s]*$/,
                                                message: "Only alphabets and numbers are allowed",
                                            },
                                        })}
                                        disabled={loader}
                                        error={!!errors.projectName}
                                        helperText={errors.projectName?.message}
                                        onKeyUp={() => { trigger('projectName'); setbackenderror('') }}
                                    />
                                </Box>

                                <Box sx={{ mb: 2 }}>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ fontWeight: 500, mb: 1, display: "block" }}
                                    >
                                        Manager <span style={{color:"red",fontWeight:800}}>*</span>
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        placeholder="manager@company.com"
                                        {...register("managerEmail", {
                                            required: "Manager Email is required",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: "Invalid email address",
                                            },
                                        })}
                                        disabled={is_view || loader}
                                        error={!!errors.managerEmail}
                                        helperText={errors.managerEmail?.message}
                                        onKeyUp={() => { trigger('managerEmail'); setbackenderror('') }}
                                        onInput={(e) => {e.target.value = e.target.value.toLowerCase()}}
                                    />
                                </Box>
                                <Box sx={{ mb: 2 }}>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ fontWeight: 500, mb: 1, display: "block" }}   
                                    >
                                        Description <span style={{color:"red",fontWeight:800}}>*</span>
                                    </Typography>
                                    <Textarea
                                        {...register("Description", {
                                            required: "Description is required",
                                            pattern: {
                                                value: /^[a-zA-Z0-9\s,\.]*$/,
                                                message: "Only alphabets, numbers, commas, and periods are allowed",
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "Description cannot exceed 100 characters",
                                            },
                                        })}
                                        placeholder="Description About The Project"
                                        minRows={2}
                                        disabled={loader}
                                    />

                                    {errors.Description && (
                                        <Typography color="error" sx={{ fontSize: "0.9rem" }}>
                                            {errors.Description.message}
                                        </Typography>
                                    )}
                                    <Alert severity="warning" sx={{ mt: 1, borderRadius: 2 }}>
                                        Warning: Access is strictly limited to managers
                                    </Alert>
                                </Box>
                                <Box sx={{ mt: 1 }}>
                                    {backenderror && <Alert severity="error">{backenderror ? backenderror : null}</Alert>}
                                </Box>
                                <Button type="submit" variant="contained" fullWidth size="large">
                                    {loader ? (
                                        <CircularProgress size={24} sx={{ color: "white" }} />
                                    ) : (
                                        <>
                                            Next <IconArrowRight />
                                        </>
                                    )}
                                </Button>
                            </form>
                </CardContent>
            </Card>
            <Dialog
                open={updatesuccess}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        setupdatesuccess(false);
                    }
                }}
                aria-labelledby="success-dialog-title"
                aria-describedby="success-dialog-description"
                maxWidth="sm"
                fullWidth
            >
                <DialogContent>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", textAlign: "center", padding: "5px", gap: 1 }}>
                        <IconCircleCheck size={80} color="#7a5afc" />
                        <Box>
                            <Typography variant="body2" color="textSecondary" sx={{ fontWeight: "600", fontSize: "2rem", color: '#7a5afc' }} >
                                Success!
                            </Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ fontWeight: "500", fontSize: "1rem" }}>
                                {updateMessage}
                            </Typography>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        sx={{
                            backgroundColor: "#7a5afc",
                            color: "white",
                            "&:hover": {
                                backgroundColor: "#5d1ac0",
                            },
                            textTransform: "none",
                        }}
                        onClick={() => { setupdatesuccess(false);navigate(is_view ? `/addstakeholder?id=${id}&is_edit=true` : `/addstakeholder?id=${Projectid}`)}}
                    >
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
            </PageContainer>
        </>
    );
};

export default NewProject;
