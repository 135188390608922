import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, CircularProgress, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material";
import axios from "axios";
// import Image from '../../Images/jumpman .png'
import { IconEdit, IconFile, IconFileExport, IconRefresh, IconX } from "@tabler/icons-react";
import avatar1 from '../../Images/user-1.jpg'
import avatar2 from '../../Images/user-2.jpg'
import avatar3 from '../../Images/user-3.jpg'
import avatar4 from '../../Images/user-4.jpg'
import Draggable from "react-draggable";
import parse from 'html-react-parser';
import { Exposure } from "@mui/icons-material";
import Breadcrumb from "../MainPages/Breadcrumb";
const ViewGeneratedDoc = () => {
    const [loader, setLoader] = useState(false);
    const [projectNames, setprojectNames] = useState([]);
    const [data, setData] = useState('')
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    const id = params.get("id");
    const namefromurl = params.get("name");
    const docName = params.get('docName')
    const Doctype = params.get('docType')
    const result = data[docName] || "Document not found";
    const [images, setImages] = React.useState([]);
    const [selectedImageIndex, setSelectedImageIndex] = React.useState(0);
    const [content, setContent] = useState(result); 
    console.log(content,'content');
    
    const [isEditing,setisEditing]= useState(false)
    const getProjects = async () => {
        try {
            setLoader(true)
            const response = await axios.get(`/apiV1/project/${id}/generate-master-input/?master_input_name=${namefromurl}`)
            if (response.status == 200) {
                setData(response.data.data)
                const wireframesObject = response.data.data.wire_frames;
                const wireframesArray = wireframesObject ? Object.values(wireframesObject) : [];
                setImages(wireframesArray);
                setprojectNames(Object.keys(response.data.data))
                setLoader(false)
            }
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }
    useEffect(() => {
        getProjects()
    }, [])
    const handleDownload = async () => {
        try {
            const Data = {
                brd_html: result,
                doc_type: docName,
            };

            setLoader(true);

            const response = await axios.post(`/apiV1/download-document/`, Data, {
                responseType: "blob",
            });

            if (response.status === 200) {
                let fileType = "";
                let fileExtension = "";

                switch (Doctype) {
                    case "pdf":
                        fileType = "application/pdf";
                        fileExtension = "pdf";
                        break;
                    case "docx":
                        fileType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                        fileExtension = "docx";
                        break;
                    case "xlsx":
                        fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                        fileExtension = "xlsx";
                        break;
                    default:
                        throw new Error("Unsupported document type");
                }
                const blob = new Blob([response.data], { type: fileType });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${docName || "document"}.${fileExtension}`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                console.log(`${fileExtension.toUpperCase()} file downloaded successfully!`);
            } else {
                console.error("Failed to download file: Unexpected response status", response.status);
            }

            setLoader(false);
        } catch (error) {
            setLoader(false);
            console.error("Error downloading file:", error);
        }
    };
    const [promptChange, setPromptChange] = useState('')
    const handleUpdate = async (data) => {
        setLoader(true)
        try {
            const formData = new FormData();
            formData.append("id", selectedImageIndex + 1);
            formData.append("project_id", id);
            formData.append("prompt", promptChange);
            formData.append("name", namefromurl);
            formData.append("html", images[selectedImageIndex]);
            let req = {
                id: selectedImageIndex,
                project_id: id,
                prompt: promptChange,
                name: namefromurl,
                html: images[selectedImageIndex],
            }
            const response = await axios.post(`/apiV1/update-wireframe/`, formData)
            if (response.status == 200) {
                setLoader(false)
                setOpen(false)
                getProjects()
                setPromptChange('')
            }
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }
    const [open, setOpen] = useState(false); 
    const handleExport = async () => {
        setLoader(true);
        try {
            const response = await axios.get(`/apiV1/project/${id}/downlaod-wireframe/?master_input_name=${namefromurl}`, { responseType: 'blob' });
            if (response.status === 200) {
                const blob = new Blob([response.data], { type: 'application/zip' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'images.zip';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            console.error('Error downloading the file:', error);
        } finally {
            setLoader(false);
        }
    };
    const BCrumb = [
        {
            to: `/generatedfiles?id=${id}`,
            title: 'Back',
        },
    ];
    const handleDocUpdate = async () => {
        try {
            setLoader(true)
            let req = {
                project_id: id,
                name: namefromurl,
                html: content,
                doc_type: docName
            }
            const response = await axios.post('/apiV1/update-documents/', req)
            console.log(response, 'response');
            if (response.status === 200) {
                setisEditing(false)
                setLoader(false)
                getProjects()
            }
        }
        catch (error) {
            console.log(error, 'Error');
            setLoader(false)
        }
    }
    return (
        <>
        <Breadcrumb breadcrumbs={BCrumb} />
            <Grid
                container
                // justifyContent="center"
                // alignItems="center"
                sx={{
                    padding: "10px 30px",
                    backgroundColor: "#fff",
                    boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                    margin: "16px 0px 0px 0px",
                    height: "85vh"
                }}
            >
                {docName !== "wire_frames" ? (<Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600, color: "#8069e5" }}>
                            View of {docName.toUpperCase()} File
                        </Typography>
                         {!isEditing ?
                         <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Button onClick={()=>setisEditing(true)} sx={{gap:1}}>Edit <IconEdit/></Button>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ gap: 1 }}
                                onClick={handleDownload}
                                disabled={loader}
                            >
                                Export <IconFileExport />
                            </Button>
                        </Box>
                         : 
                     <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}> 
                        <Button onClick={handleDocUpdate} sx={{gap:1}}>Save <IconFile/></Button>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ gap: 1 ,backgroundColor:"crimson"}}
                            onClick={()=>setisEditing(false)}
                        >
                            Cancel <IconX />
                        </Button>
                        </Box>
                        } 
                    </Box>

                    <Box sx={{ mb: 2, position: 'relative' }}>
                        {loader && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    zIndex: 1,
                                }}
                            >
                                <CircularProgress size={24} />
                            </Box>
                        )}

                        <div
                            style={{
                                position: 'relative',
                                display: 'inline-block',
                                width: '100%',
                                maxWidth: '1600px',
                            }}
                        >
                            <div
                                style={{
                                    border: '1px solid #ccc',
                                    borderRadius: '12px',
                                    padding: '10px',
                                    fontSize: '16px',
                                    minHeight: '150px',
                                    height: '77vh',
                                    // whiteSpace: 'pre-wrap',
                                    overflowWrap: 'break-word',
                                    overflowY: 'auto',
                                    opacity: loader ? 0.5 : 1,
                                    pointerEvents: loader ? 'none' : 'auto',
                                }}
                                contentEditable={isEditing}
                                dangerouslySetInnerHTML={{ __html: result }}
                                onInput={(e) => {
                                    const updatedContent = e.currentTarget.innerHTML;
                            
                                            const fullHTMLContent = `<!DOCTYPE html>
                                    <html lang="en">
                                    <head>
                                        <meta charset="UTF-8">
                                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                        <title>Editable Content</title>
                                    </head>
                                    <body>
                                        ${updatedContent}
                                    </body>
                                    </html>`;
                            
                                    setContent(fullHTMLContent);
                                }}
                            />
                        </div>
                    </Box>
                    {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, mr: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ gap: 2 }}
                            onClick={handleDownload}
                            disabled={loader}
                        >
                            Export <IconFileExport />
                        </Button>
                    </Box> */}
                </Grid>
                ) : (
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={5} md={2} lg={2}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        border: "1px solid #ccc",
                                        borderRadius: "8px",
                                        padding: "10px",
                                        maxHeight: "80vh",
                                        overflowY: "auto",
                                        // scrollbarWidth: "thin",
                                        // "&::-webkit-scrollbar": {
                                        //     width: "8px",
                                        //     backgroundColor: "red",
                                        // }
                                    }}
                                >
                                    {Array.isArray(images) && images.length > 0 ? (
                                        images.map((image, index) => (
                                            <div
                                                key={index}
                                                style={{
                                                    position: "relative",
                                                    display: "inline-block",
                                                    width: "100%",
                                                    marginBottom: "10px",
                                                }}
                                                onClick={() => setSelectedImageIndex(index)}
                                            >
                                                <div
                                                    style={{
                                                        border: "1px solid #ccc",
                                                        borderRadius: "12px",
                                                        padding: "10px",
                                                        fontSize: "16px",
                                                        minHeight: "150px",
                                                        height: "20vh",
                                                        whiteSpace: "pre-wrap",
                                                        overflowWrap: "break-word",
                                                        overflowY: "hidden",
                                                        opacity: loader ? 0.5 : 1,
                                                        pointerEvents: loader ? "none" : "auto",
                                                        borderColor: selectedImageIndex === index ? "#8069e5" : "#ddd",
                                                        boxSizing: "border-box",
                                                        width: "100%",
                                                        maxWidth: "100%",
                                                    }}
                                                    dangerouslySetInnerHTML={{ __html: image }}
                                                />
                                                <Typography sx={{ textAlign: "center", m: 2 }}>Screen {index + 1}</Typography>
                                            </div>
                                        ))

                                    ) : (
                                        <Typography variant="body2" color="text.secondary">
                                            No images available
                                        </Typography>
                                    )}
                                </Box>
                            </Grid>

                            {/* Right Side: Image Preview */}
                            <Grid item xs={12} sm={7} md={10} lg={10}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        border: "1px solid #ccc",
                                        borderRadius: "8px",
                                        height: "77vh",
                                        overflow: "hidden",
                                        padding: "20px"
                                    }}
                                >
                                    {images.length > 0 && selectedImageIndex !== null ? (
                                        <div
                                            style={{
                                                position: "relative",
                                                display: "inline-block",
                                                width: "100%",
                                                maxWidth: "1500px",
                                            }}
                                            disabled={true}
                                        >
                                            <div
                                                style={{
                                                    borderRadius: "12px",
                                                    fontSize: "16px",
                                                    minHeight: "150px",
                                                    padding: "20px",
                                                    height: "80vh",
                                                    overflowWrap: "break-word",
                                                    overflowY: "auto",
                                                    opacity: loader ? 0.5 : 1,
                                                    pointerEvents: loader ? "none" : "auto",
                                                    outline: "none",
                                                    border: "0px solid red",
                                                }}
                                                contentEditable={false}
                                                onFocus={(e) => e.preventDefault()} 
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                {parse(images[selectedImageIndex].replace(/\\"/g, '"'))}
                                            </div>

                                        </div>




                                    ) : (
                                        <Typography variant="h6" sx={{ textAlign: "center", color: "text.secondary" }}>
                                            No Preview Available
                                        </Typography>
                                    )}
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, mr: 2, mb: 1 }}>
                                    {/* <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ gap: 1, height: 40, mr: 1 }}
                                        disabled={loader}
                                    >
                                        Regenerate <IconRefresh />
                                    </Button> */}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ gap: 1, height: 40, mr: 1 }}
                                        onClick={() => setOpen(true)}
                                        disabled={loader}
                                    >
                                        Update Changes <IconEdit />
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ gap: 1, height: 40 }}
                                        onClick={handleExport}
                                        disabled={loader}
                                    >
                                       {loader ? (
                                            <CircularProgress size={30} sx={{ color: '#fff' }} />
                                        ) : (
                                            <>Export<IconFileExport /></>
                                        )} 
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>


                )}
            </Grid>
            {/* <Draggable onDrag={handleDrag}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "20px",
                        left: "20px",
                        backgroundColor: "#8069e5",
                        padding: "10px",
                        borderRadius: "8px",
                        cursor: "move",
                        color: "#fff",
                        zIndex: 10,
                    }}
                    onClick={toggleBox}
                >
                    <Typography variant="button">Open Box</Typography>
                </Box>
            </Draggable> */}

            {/* Box that opens to the side of the button */}
            {open && (
                <Box
                    component="form"
                    onSubmit={(e) => {
                        e.preventDefault(); // Prevent default form submission behavior
                        handleUpdate(); // Call the update function
                    }}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 2,
                        position: "absolute",
                        top: `10%`,
                        left: `80%`,
                        width: "19%",
                        backgroundColor: "#fff",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        padding: "20px",
                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                        zIndex: 9,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            Quick Edit
                        </Typography>
                        <IconButton
                            aria-label="close"
                            onClick={() => setOpen(false)}
                            sx={{
                                color: (theme) => theme.palette.grey[500],
                            }}
                            disabled={loader}
                        >
                            <IconX />
                        </IconButton>
                    </Box>
                    <textarea
                        name="value"
                        value={promptChange}
                        onChange={(e) => setPromptChange(e.target.value)}
                        placeholder="Give the prompt here for the changes and customize the wireframe"
                        style={{
                            width: "100%",
                            height: "100px",
                            padding: "8px",
                            fontSize: "16px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            resize: "vertical",
                            outline: "none",
                        }}
                    />

                    <Box sx={{ display: "flex", gap: 2, width: "100%", justifyContent: "flex-end" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ gap: 1, height: 40, mr: 1 }}
                            onClick={() => {setOpen(false);setPromptChange('')}}
                            disabled={loader}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ gap: 1, height: 40, mr: 1 }}
                            disabled={loader}
                            style={{
                                position: "relative",
                            }}
                        >
                            {loader ? (
                                <CircularProgress size={30} sx={{ color: '#fff' }} />
                            ) : (
                                <>Update</>
                            )}
                        </Button>
                    </Box>
                </Box>
            )}

            {/* <Dialog
                open={open}
                onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                        setOpen(false);
                    }
                }}
                PaperProps={{
                    sx: {
                        position: "absolute",
                        right: 10,
                        top: "10%",
                        margin: 0,
                        width: "20%",
                        pointerEvents: "auto",
                    },
                }}
                disableEscapeKeyDown
                disableEnforceFocus 
                disableScrollLock 
                disablePortal 
                hideBackdrop 
            >

                <DialogTitle
                    id="form-dialog-title"
                    sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                    Quick Edit
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <IconX />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <Box
                        component="form"
                        onSubmit={(e) => {
                            e.preventDefault(); // Prevent default form submission behavior
                            handleUpdate(); // Call the update function
                        }}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 2,
                            mt: 1,
                        }}
                    >
                        <textarea
                            name="value"
                            value={promptChange}
                            onChange={(e) => setPromptChange(e.target.value)}
                            placeholder="Give the prompt here for the changes and customize the wireframe"
                            style={{
                                width: "100%",
                                height: "100px",
                                padding: "8px",
                                fontSize: "16px",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                resize: "vertical",
                            }}
                        />


                        {/* <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    right: "8px",
                                    transform: "translateY(-50%)",
                                    height: "80%",
                                }}
                            >
                                Regenerate
                            </Button> 
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleUpdate}
                        disabled={loader}
                        style={{
                            position: "relative",
                        }}
                    >
                        {loader ? (
                            <CircularProgress size={20} sx={{ color: '#fff' }} />
                        ) : (
                            <>
                                Regenerate
                            </>
                        )}
                    </Button>
                </DialogActions>
            </Dialog> */}
        </>
    );
};

export default ViewGeneratedDoc;
